import React, { useContext, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { GameFilesUpload } from './GameFilesUpload';
import { UploadedFile } from '../../../../../../common/domain/upload';
import { UploadInstructions } from './UploadInstructions';
import TypeSelect from './TypeSelect';
import { StyledBodyText, StyledHeaderText } from '../../../../../../common/Styleguide/Common/Text';
import { StyledButton } from '../../../../../../common/Styleguide/Common/Button';
import { StyledInput } from '../../../../../../common/Styleguide/Common/Input';
import UnityCompressionOption from './UnityLoaderOptions/UnityCompressionOption';
import GameDataSaveOptions from './GameDataSaveOptions';
import { DOCS_URL } from '../../../../SideMenu/Menu';
import { GameLoaderType, UNITY_GAME_LOADERS } from '../../../../../../common/domain/game';
import { NON_RELEASED_STATUSES } from '../UpdateSubmission/UpdateSubmission.types';
import { renderFileWarnings } from '../../helpers/warningHelpers';
import { SubmissionContext } from '../Context/SubmissionProvider';
import { StyledContainer } from '../../../../../../common/Styleguide/Common/Container';
import { COLORS } from '../../../../../../common/Styleguide/Common/colors';
import { renderHardWarning } from './utils';
import TextAlert from '../../TextAlert';

/**
 * This component is displayed when you submit a new game, and when you want to submit a new file update (this one still do be done).
 * It renders:
 * - Game type select (HTML5, Unity, etc.)
 * - File upload, or iframe link input
 * - Game options (APS toggles, mobile friendly checkbox, iap checkbox)
 */
const UploadGameFilesForm: React.FC = () => {
  const {
    baseForm,
    attemptedToSave,
    baseFormProblems,
    submission,
    gameBuild,
    updateBaseForm,
    uploadingFilesSoftProblems,
    uploadingFilesHardProblems,
    onFilesToUploadSet,
    isReleased,
  } = useContext(SubmissionContext);

  const { gameLoaderType, files, hasIAP, multiplayerOptions, isAndroidFriendly, isIOSFriendly, unity56Encoding, progressSaveType } =
    baseForm;

  const [instructionDialogOpen, setInstructionDialogOpen] = useState<boolean>(false);
  const [showUnityiOSWarning, setShowUnityiOSWarning] = useState<boolean>(false);

  const renderLoaderTypeSelect = () => {
    const { gameLoaderType } = baseForm;
    const typeError = attemptedToSave && baseFormProblems?.includes('GAME_LOADER_TYPE_MISSING');
    const excludedGameLoader: GameLoaderType[] = [];
    // exclude iframe loader for released submissions, as we don't want developers to change to iframe once the submission is released
    if (submission && gameBuild && gameBuild.loaderType !== 'iframe' && !NON_RELEASED_STATUSES.includes(submission.status)) {
      excludedGameLoader.push('iframe');
    }
    return (
      <>
        <TypeSelect
          error={typeError}
          gameLoaderType={gameLoaderType || undefined}
          onGameLoaderTypeChange={(gameLoaderType) => {
            updateBaseForm({ ...baseForm, gameLoaderType });
          }}
          disabled={false}
          isGameJam={false}
        />
        {typeError && (
          <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
            {`Please select a type`}
          </StyledBodyText>
        )}
      </>
    );
  };

  const renderIframeLinkInput = () => {
    const iframeLink = baseForm.iframeLink;
    const isHttp = !!iframeLink && iframeLink.startsWith('http://');
    const httpHelperText = isHttp
      ? 'Preview will not work for HTTP, if you support HTTPS please use that link instead.'
      : 'http(s)://example.com';
    const iframeError = (attemptedToSave && baseFormProblems?.includes('IFRAME_INVALID_URL')) || isHttp;
    return (
      <>
        <StyledBodyText variant="h3" sx={{ marginY: 1.25 }}>
          IFrame link
        </StyledBodyText>
        <StyledInput
          required
          type="url"
          value={iframeLink ? iframeLink : ''}
          onChange={(e) => {
            updateBaseForm({ ...baseForm, iframeLink: e.currentTarget.value });
          }}
          error={iframeError}
        />
        <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="black10">
          {httpHelperText}
        </StyledBodyText>
      </>
    );
  };

  const renderGameFilesUpload = () => {
    const { files, gameLoaderType } = baseForm;
    const uploadMissingError =
      attemptedToSave && (baseFormProblems?.includes('HTML5_FILES_MISSING') || baseFormProblems?.includes('UNITY_FILES_MISSING'));
    const uploadingError = attemptedToSave && baseFormProblems?.includes('GAME_FILE_UPLOAD_IN_PROGRESS');
    const limitsExceededError =
      baseFormProblems?.includes('TOTAL_FILE_COUNT_EXCEEDED') ||
      baseFormProblems?.includes('TOTAL_FILE_SIZE_EXCEEDED') ||
      uploadingFilesHardProblems?.includes('TOTAL_FILE_COUNT_EXCEEDED') ||
      uploadingFilesHardProblems?.includes('TOTAL_FILE_SIZE_EXCEEDED');
    return (
      <>
        <div style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
          <StyledBodyText variant="h3" sx={{ m: 0 }}>
            Files Upload <span>*</span>
          </StyledBodyText>
          <div>
            <StyledButton onClick={() => setInstructionDialogOpen(!instructionDialogOpen)} height={22}>
              <HelpIcon />
            </StyledButton>
            <UploadInstructions open={instructionDialogOpen} onClose={() => setInstructionDialogOpen(false)} />
          </div>
        </div>
        <GameFilesUpload
          initialFiles={files || []}
          error={uploadMissingError || uploadingError || limitsExceededError}
          gameLoaderType={gameLoaderType ?? undefined}
          onUploadsStarted={handleUploadStarted}
          onFilesModified={handleUploadCompleted}
          onFilesToUploadSet={onFilesToUploadSet}
        />
        {uploadMissingError && renderHardWarning(`Please upload your files`)}
        {uploadingError && renderHardWarning(`Please wait until Game file upload is complete`)}
        {renderFileWarnings(uploadingFilesHardProblems || [], uploadingFilesSoftProblems || [])}
      </>
    );
  };

  const handleUploadStarted = () => {
    updateBaseForm({ ...baseForm, uploadInProgress: true });
  };

  const handleUploadCompleted = (files: UploadedFile[]) => {
    updateBaseForm({ ...baseForm, files, uploadInProgress: false });
  };

  const handleSupportsMobileUpdate = (supports: boolean) => {
    const isAndroidFriendly = supports;
    // unity games by default are iOS disabled if they support mobile, QA team will make sure they work fine on iOS
    const isIOSFriendly = !!(supports && gameLoaderType && !UNITY_GAME_LOADERS.includes(gameLoaderType));
    updateBaseForm({ ...baseForm, isAndroidFriendly, isIOSFriendly, orientation: supports ? 'BOTH' : null });
    setShowUnityiOSWarning(!!(gameLoaderType && UNITY_GAME_LOADERS.includes(gameLoaderType) && supports));
  };

  return (
    <>
      <StyledContainer
        sx={{
          width: 900,
          m: 2,
          mt: 3,
          p: 2,
          px: 3,
        }}
      >
        <StyledHeaderText
          variant="h2"
          sx={{
            paddingY: 1,
            margin: 0,
          }}
        >
          Files
        </StyledHeaderText>

        {!submission && (
          <TextAlert>
            Please read{' '}
            <a href={DOCS_URL} target="_blank" rel="noreferrer" style={{ color: COLORS.warning[100], textDecoration: 'underline' }}>
              our CrazyGames documentation
            </a>{' '}
            carefully before submitting a game!
          </TextAlert>
        )}

        {renderLoaderTypeSelect()}

        <UnityCompressionOption
          gameLoaderType={gameLoaderType}
          unity56Encoding={unity56Encoding}
          files={files || []}
          onChange={(unity56Encoding) => updateBaseForm({ ...baseForm, unity56Encoding })}
        />

        {gameLoaderType === 'iframe' && renderIframeLinkInput()}

        {gameLoaderType && gameLoaderType !== 'iframe' && renderGameFilesUpload()}
      </StyledContainer>
      <StyledContainer
        sx={{
          width: 900,
          m: 2,
          mt: 3,
          p: 2,
          px: 3,
        }}
      >
        <StyledHeaderText
          variant="h2"
          sx={{
            paddingY: 1,
            margin: 0,
          }}
        >
          Options
        </StyledHeaderText>
        <StyledBodyText variant="h3" sx={{ mb: 1.25 }}>
          Gameplay
        </StyledBodyText>
        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 8 }}>
          {/* to simplify things for the devs we display a single "Supports mobile" checkbox, which automatically checks both android and ios friendly. QA team will check and decide if iOS friendly should stay. */}
          <FormControlLabel
            label="The game supports mobile devices"
            checked={isAndroidFriendly || isIOSFriendly}
            control={<Checkbox onChange={(e) => handleSupportsMobileUpdate(e.currentTarget.checked)} />}
          />

          {showUnityiOSWarning && (
            <TextAlert>
              Unity games are disabled by default on iOS. Your game will be tested and enabled based on engagement stats.
            </TextAlert>
          )}

          <FormControlLabel
            label="The game is an online multiplayer game"
            checked={multiplayerOptions?.isMultiplayer}
            disabled={isReleased} // we don't currently allow modifying multiplayer stuff for released games, this may change once multiplayer flags are moved to game
            control={
              <Checkbox onChange={(e) => updateBaseForm({ ...baseForm, multiplayerOptions: { isMultiplayer: e.currentTarget.checked } })} />
            }
          />
          <FormControlLabel
            label="The game has in-game purchases"
            checked={hasIAP}
            control={<Checkbox onChange={(e) => updateBaseForm({ ...baseForm, hasIAP: e.currentTarget.checked })} />}
          />

          {hasIAP && (
            <TextAlert>
              In-game purchases are only allowed for selected games and should use CrazyGames' Xsolla account/integration. Your game might
              be rejected if this does not apply to your game.
            </TextAlert>
          )}
        </div>
        <GameDataSaveOptions value={progressSaveType} onChange={(progressSaveType) => updateBaseForm({ ...baseForm, progressSaveType })} />
        {!gameLoaderType && (
          <StyledBodyText variant="bodyLower" sx={{ color: COLORS.white[30] }}>
            Additional options will be displayed once you select game type
          </StyledBodyText>
        )}
      </StyledContainer>
    </>
  );
};

export default UploadGameFilesForm;
