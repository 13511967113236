import { gql } from '@apollo/client';
import { mutate } from './graphql';

const CREATE_VIDEO_THUMBNAIL = gql`
  mutation createVideoThumbnail($input: VideoThumbnailCreateInput!) {
    videoThumbnailCreate(input: $input) {
      thumbnail
      original
    }
  }
`;

export interface CreateVideoThumbnailMutation {
  videoThumbnailCreate: {
    thumbnail: string;
    original: string;
  };
}

export interface VideoThumbnailCreateInput {
  slug: string;
  uploadId: string;
  width: number;
  height: number;
}

export function createVideoThumbnail(input: VideoThumbnailCreateInput) {
  return mutate<VideoThumbnailCreateInput, CreateVideoThumbnailMutation>(CREATE_VIDEO_THUMBNAIL, input);
}
