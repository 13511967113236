import { GAME_ORIENTATION } from '../../../../../../common/CzyForm/OrientationSelector';
import { GameLoaderType } from '../../../../../../common/domain/game';
import { Unity56Encoding } from '../../../UserDeveloper/Submission/GameBaseForm/UnityLoaderOptions/UnityCompressionSelect';
import { gql } from '@apollo/client';
import { QA_RESULT_FRAGMENT } from './qa-result-graphql';
import { GAME_FILE_FRAGMENT } from './game-file-graphql';
import { SubmissionAPSDetail } from '../../../UserDeveloper/SubmitGame/submit-game-graphql';
import { FULLSCREEN_SETTING } from '../../Game/GameframeConfig/GameframeConfig';
import { QAResult } from '../../../UserDeveloper/QATool/helpers/qa-result-types';

export type GameBuildStatus = 'DRAFT' | 'SUBMITTED' | 'REJECTED' | 'ACTIVE' | 'ACCEPTED';

export interface UnityConfig {
  encoding?: Unity56Encoding;
  saveFileNames?: string[];
}

export interface GameFile {
  uploadFileId: string;
  fileName: string;
  publicUrl: string;
  /**
   * Size in bytes
   */
  size: number;
}

export interface GameBuild {
  // general data
  id: string;
  gameId: string;
  createdAt: string;
  status: GameBuildStatus;

  // files
  loaderType: GameLoaderType;
  loaderConfigJson: string;
  gameframeConfigJson: string;
  files?: GameFile[] | null;
  apsConfig: SubmissionAPSDetail;
  unityConfig?: UnityConfig | null;
  totalFileSize?: number | null;
  s3Location?: string | null;

  // options
  fullscreen: FULLSCREEN_SETTING;
  iosFriendly: boolean;
  androidFriendly: boolean;
  chromebookFriendly: boolean;
  safariFriendly: boolean;
  hasIap: boolean;
  mobileOrientation: GAME_ORIENTATION | null;

  // admin flags
  allowEmbed: boolean;
  sandbox: boolean;
  isEligibleForMobileHomepage: boolean;

  /** Developer qa result */
  qaResult?: QAResult;
  /** Admin QA result from our content team */
  adminQaResult?: QAResult;
  updateNotes?: string;
}

export const BUILDS_FRAGMENT = gql`
  fragment GameBuildFragment on GameBuild {
    id
    gameId
    createdAt
    status

    loaderType
    loaderConfigJson
    gameframeConfigJson
    files {
      ...GameFileragment
    }
    apsConfig {
      progressType
    }
    unityConfig {
      encoding
      saveFileNames
    }
    totalFileSize
    s3Location

    fullscreen
    iosFriendly
    androidFriendly
    isEligibleForMobileHomepage
    chromebookFriendly
    safariFriendly
    hasIap
    mobileOrientation

    allowEmbed
    sandbox

    qaResult {
      ...QAResultFragment
    }
    adminQaResult {
      ...QAResultFragment
    }
    updateNotes
  }
  ${QA_RESULT_FRAGMENT}
  ${GAME_FILE_FRAGMENT}
`;
