import * as React from 'react';
import { UploadedFile } from '../../../../../common/domain/upload';
import { createVideoThumbnail } from '../../../../../common/create-video-thumbnail-mutation';
import { CircularProgress, Typography } from '@mui/material';
import { config } from '../../../../../common/config';
import { ApolloError } from '@apollo/client';
import { GraphQLError } from '../../../../../common/components/ApolloError/GraphQLError';
import { uploadFile } from '../../../../../common/upload';
import withSession, { WithSession } from '../../../../../common/Session/withSession';
import { COLORS } from '../../../../../common/Styleguide/Common/colors';
import { StyledButton } from '../../../../../common/Styleguide/Common/Button';
import { ClearOutlined, Edit, VideoCallOutlined } from '@mui/icons-material';
import { StyledBodyText } from '../../../../../common/Styleguide/Common/Text';

export interface VideoUploadProps extends WithSession {
  onChange: (thumbnailLocation: string | null, originalLocation: string | null) => void;
  onUploadStatusChange?: (status: VideoUploadProgress) => void;
  slug: string;
  originalVideo: string | null;
  label: string | null;
  error?: string;
  videoContainerRatio: {
    width: number;
    height: number;
  };
}

export type VideoUploadProgress = 'not-started' | 'uploading' | 'converting';

export interface VideuoUploadState {
  video: string | null;
  progress: VideoUploadProgress;
  error?: Error;
}

class VideoUpload extends React.Component<VideoUploadProps, VideuoUploadState> {
  private inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: VideoUploadProps) {
    super(props);
    this.state = {
      progress: 'not-started',
      video: props.originalVideo,
    };
    this.inputRef = React.createRef();
  }

  render() {
    const { width, height } = this.props.videoContainerRatio;
    const { label, error } = this.props;

    return (
      <div>
        {label && (
          <Typography variant="subtitle1" sx={{ mb: 1.5 }}>
            <b>{label}</b>
          </Typography>
        )}
        <div
          style={{
            border: `2px dashed ${error ? COLORS.alert[100] : COLORS.black[30]}`,
            width: width * 1.5,
            height: height * 1.5,
            borderRadius: 8,
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          {this.renderPreview()}
        </div>
        {this.renderProgress()}
        {error && (
          <StyledBodyText sx={{ m: 0.5 }} variant="bodyLower2" color="error">
            {error}
          </StyledBodyText>
        )}
        <div style={{ display: 'none' }}>
          <input
            ref={this.inputRef}
            type="file"
            onChange={(event) => this.onFileInputChange(event, this.props.videoContainerRatio.height, this.props.videoContainerRatio.width)}
          />
        </div>
      </div>
    );
  }

  private renderPreview() {
    const { video } = this.state;
    if (!video) {
      return this.renderNoVideo();
    }
    const { videoCdnPrefix } = config.aws;
    // we assign key to the video tag, if not only the source
    // is rerendered, and the actual video does not change
    return (
      <>
        <video
          key={video}
          autoPlay={true}
          loop={true}
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }}
        >
          <source src={`${videoCdnPrefix}/${video}`} type="video/mp4" />
        </video>
        <div
          style={{
            position: 'absolute',
            inset: 0,
            padding: 8,
            gap: 8,
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            opacity: 0,
            transition: 'opacity 0.3s',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.opacity = '1')}
          onMouseLeave={(e) => (e.currentTarget.style.opacity = '0')}
        >
          <StyledButton variant="contained" color="white" onClick={this.onClearClicked} disabled={this.shouldDisableButtons()} height={34}>
            <ClearOutlined /> Clear
          </StyledButton>
          <StyledButton variant="contained" color="white" onClick={this.onUploadClicked} disabled={this.shouldDisableButtons()} height={34}>
            <Edit /> Update
          </StyledButton>
        </div>
      </>
    );
  }

  private renderNoVideo() {
    return (
      <div style={{ position: 'absolute', inset: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <StyledButton variant="contained" onClick={this.onUploadClicked} disabled={this.shouldDisableButtons()} height={34}>
          <VideoCallOutlined /> Upload
        </StyledButton>
      </div>
    );
  }

  private renderProgress() {
    const { progress, error } = this.state;
    if (error) {
      return this.renderError(error);
    }
    switch (progress) {
      case 'not-started':
        return;
      case 'uploading':
        return this.renderUploading();
      case 'converting':
        return this.renderConverting();
      default:
        return;
    }
  }

  private renderError(error: Error) {
    return <GraphQLError error={error} />;
  }

  private renderUploading() {
    return this.renderSpinner('uploading...');
  }

  private renderConverting() {
    return this.renderSpinner('converting...');
  }

  private renderSpinner(msg: string) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 8, gap: 4 }}>
        <CircularProgress variant="indeterminate" size={16} />
        {msg}
      </div>
    );
  }

  private uploadVideo = async (video: File, height: number, width: number) => {
    const { session } = this.props;
    this.setState({ progress: 'uploading' });
    try {
      const uploads = await uploadFile(video, session, video.name);
      const uploadResponse = uploads[0];
      const uploadedFile: UploadedFile = {
        uploadId: uploadResponse.uploadId,
        path: video.name,
        size: video.size,
        temporaryUrl: '',
      };
      await this.convertVideo(uploadedFile, height, width);
    } catch (e) {
      this.setState({
        error: e as Error,
      });
    }
  };

  private convertVideo = async (file: UploadedFile | null, height: number, width: number) => {
    if (!file) {
      return this.handleFinishedConverting(null, null);
    }
    this.setState({
      progress: 'converting',
      error: undefined,
    });
    try {
      const res = await this.resizeVideo(file, height, width);
      const { thumbnail, original } = res;
      return this.handleFinishedConverting(thumbnail, original);
    } catch (e) {
      console.error('failed to resize video', e);
      if (e instanceof ApolloError || e instanceof Error) {
        this.setState({ error: e });
      } else {
        console.error(e);
      }
      this.setState({ progress: 'not-started' });
    }
  };

  private async resizeVideo(file: UploadedFile, height: number, width: number) {
    const { slug } = this.props;
    const resp = await createVideoThumbnail({
      slug: slug,
      uploadId: file.uploadId,
      height: height,
      width: width,
    });
    return resp.data!.videoThumbnailCreate;
  }

  private onClearClicked = () => {
    const { onChange } = this.props;
    this.setState({ video: null });
    onChange(null, null);
  };

  private handleFinishedConverting(thumbnailLocation: string | null, originalLocation: string | null) {
    this.setState({
      progress: 'not-started',
      video: thumbnailLocation,
    });
    const { onChange } = this.props;
    onChange(thumbnailLocation, originalLocation);
  }

  private onUploadClicked = () => {
    const fileInput = this.getFileInput();
    // custom select file button -> manual click to activate element
    // *may not work* in IE/Safari - to be verified
    fileInput.click();
  };

  private onFileInputChange = async (evt: React.ChangeEvent<HTMLInputElement>, thumbnailHeight: number, thumbnailWidth: number) => {
    const { files } = evt.target;
    if (!files) {
      console.warn('Files is not set');
      return;
    }

    await this.uploadVideo(files[0], thumbnailHeight, thumbnailWidth);

    // unset the value to allow to reselect the same file (does not trigger onChange otherwise)
    this.getFileInput().value = '';
  };

  private getFileInput(): HTMLInputElement {
    const input = this.inputRef.current;
    if (!input) {
      throw new Error('FileInput div ref is not set');
    }
    return input;
  }

  private shouldDisableButtons = () => {
    return this.state.progress !== 'not-started';
  };

  componentDidUpdate(_prevProps: VideoUploadProps, prevState: VideuoUploadState) {
    if (prevState.progress !== this.state.progress) {
      if (this.props.onUploadStatusChange) {
        this.props.onUploadStatusChange(this.state.progress);
      }
    }
  }
}

export default withSession<VideoUploadProps>(VideoUpload);
