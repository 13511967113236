import { config } from './config';
import { SessionContextData } from './Session/SessionContext';
import { downloadFileFromBlob } from './download';
import { GameVideo } from './graphql/games/game';

export function prefixGraphUrl(path: string): string {
  return `${config.graph}/${path}`;
}

const DOWNLOAD_TAG_LENGTH_ENDPOINT = prefixGraphUrl('tag-description-lengths');
const DOWNLOAD_GAME_LENGTH_ENDPOINT = prefixGraphUrl('game-description-lengths');

async function processDownloadDescriptionLengthReport(session: SessionContextData, endpoint: string, filename: string) {
  if (!session.isLoggedIn()) {
    return Promise.reject('[upload] not authenticated');
  }
  const token = await session.getUser().firebaseUser.getIdToken();

  const resp = await fetch(endpoint.toString(), {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (resp.status === 200) {
    const blob = await resp.blob();
    return downloadFileFromBlob(blob, filename, {
      fileType: 'text/csv',
      extension: 'csv',
    });
  } else {
    const text = await resp.text();
    console.error(text);
    throw new Error(text);
  }
}

export async function downloadDescriptionLengthReport(session: SessionContextData, type: 'tag' | 'game') {
  switch (type) {
    case 'tag':
      return await processDownloadDescriptionLengthReport(session, DOWNLOAD_TAG_LENGTH_ENDPOINT, 'tag_description_length');
    case 'game':
      return await processDownloadDescriptionLengthReport(session, DOWNLOAD_GAME_LENGTH_ENDPOINT, 'game_description_length');
    default:
      throw new Error('Unknown type for description length report');
  }
}

export function parseGameVideoJson(rawVideo: string | null) {
  let videoOriginal: string | null = null;
  let portraitVideoOriginal: string | null = null;
  let landscapeThumb: string | null = null;
  let portraitThumb: string | null = null;
  if (rawVideo) {
    const videoParsed: GameVideo = JSON.parse(rawVideo);
    videoOriginal = videoParsed ? videoParsed.original : null;
    portraitVideoOriginal = videoParsed ? videoParsed.portraitOriginal : null;
    landscapeThumb = videoParsed.sizes && videoParsed.sizes.length > 0 ? videoParsed.sizes[0].location : null;
    portraitThumb = videoParsed.portraitSizes && videoParsed.portraitSizes.length > 0 ? videoParsed.portraitSizes[0].location : null;
  }
  return {
    videoOriginal,
    portraitVideoOriginal,
    landscapeThumb,
    portraitThumb,
  };
}
