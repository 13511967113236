import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import React from 'react';
import { StyledButton, StyledLoadingButton } from '../../../../../common/Styleguide/Common/Button';
import { StyledBodyText } from '../../../../../common/Styleguide/Common/Text';
import { MailReceiver } from '../../../../../modules/Mailer/Mailer';
import { Submission } from './submission-graphql';
import { ApolloError } from '@apollo/client';
import { deleteSubmission } from './Mutations/delete-submission-mutation';
import { useHistory } from 'react-router-dom';
import { GraphQLError } from '../../../../../common/components/ApolloError/GraphQLError';
import { COLORS } from '../../../../../common/Styleguide/Common/colors';

type DeleteState = 'not-started' | 'in-progress';

const SubmissionDeleteButton = ({ submission }: { submission: Submission }) => {
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const [deleteState, setDeleteState] = React.useState<DeleteState>('not-started');
  const [apolloError, setApolloError] = React.useState<ApolloError | undefined>(undefined);
  const history = useHistory();

  // keep this check in sync with Graph
  const canBeDeleted = submission.gameStatus !== 'PUBLISHED';

  if (!canBeDeleted) {
    return null;
  }

  const onDeleteClick = () => {
    if (deleteState !== 'not-started') {
      return;
    }
    setApolloError(undefined);
    setShowConfirmDialog(true);
  };

  const closeConfirmDialog = () => {
    setShowConfirmDialog(false);
  };

  const onDeleteConfirm = async () => {
    if (deleteState !== 'not-started') {
      return;
    }
    setDeleteState('in-progress');
    try {
      await deleteSubmission({ id: submission.id });
      closeConfirmDialog();
      history.push('/games');
    } catch (e) {
      setApolloError(e as ApolloError);
    } finally {
      setDeleteState('not-started');
    }
  };

  return (
    <>
      <StyledButton variant="link" onClick={onDeleteClick}>
        Remove game
      </StyledButton>
      {showConfirmDialog && (
        <Dialog open={showConfirmDialog} onClose={closeConfirmDialog}>
          <DialogTitle id="alert-dialog-slide-title">Delete submission?</DialogTitle>
          <DialogContent>
            <StyledBodyText id="alert-dialog-slide-description" color="white60" sx={{ textAlign: 'center', px: 2 }}>
              This will delete your submission. This operation cannot be undone. If you have questions regarding your submission you can
              contact us at <a href={`mailto:${MailReceiver.submissions}`}>{MailReceiver.submissions}</a>.
            </StyledBodyText>
            {apolloError && <GraphQLError error={apolloError} textColor={COLORS.alert[100]} />}
          </DialogContent>
          <DialogActions>
            <StyledButton onClick={closeConfirmDialog} variant="contained" color="grey" height={50} sx={{ minWidth: 130, mr: 2 }}>
              Cancel
            </StyledButton>
            <StyledLoadingButton
              loading={deleteState !== 'not-started'}
              onClick={onDeleteConfirm}
              variant="contained"
              color="red"
              height={50}
              style={{ minWidth: 130 }}
            >
              Delete
            </StyledLoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default SubmissionDeleteButton;
