import React, { useContext, useState } from 'react';
import { StyledBodyText } from '../../../../../../common/Styleguide/Common/Text';
import { Alert, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { loadersWithSaveDataToggles, unityLoadersWithDataSave } from '../../../../../../common/domain/game';
import { ProgressSaveType } from './baseFormHelper';
import { DOCS_URL } from '../../../../SideMenu/Menu';
import { SubmissionContext } from '../Context/SubmissionProvider';
import { COLORS } from '../../../../../../common/Styleguide/Common/colors';
import TextAlert from '../../TextAlert';

export interface GameDataSaveOptionsProps {
  onChange: (value: ProgressSaveType) => void;
  value?: ProgressSaveType;
  isNonEditable?: boolean;
}

const GameDataSaveOptions: React.FC<GameDataSaveOptionsProps> = ({ value, onChange, isNonEditable = false }) => {
  const { baseForm, gameBuild, baseFormProblems, attemptedToSave, submission } = useContext(SubmissionContext);
  const [originalProgressSaveType] = useState(baseForm?.progressSaveType);
  const gameLoaderType = baseForm?.gameLoaderType;
  const showSDKPSNotSelectedWarning = value !== 'SDKPS' && gameBuild?.qaResult?.SDKFeatures?.includes('useDataModule');

  if (!gameLoaderType || !loadersWithSaveDataToggles.includes(gameLoaderType)) {
    return null;
  }

  const hasApsInfoMissingProblem = attemptedToSave && baseFormProblems?.includes('APS_INFO_MISSING');

  return (
    <>
      <StyledBodyText variant="h3" sx={{ mt: 3, mb: 1.25 }}>
        Does your game save progress? *
      </StyledBodyText>
      <RadioGroup
        sx={{ pl: 1, border: `solid ${hasApsInfoMissingProblem ? COLORS.alert[100] : 'transparent'} 2px`, borderRadius: 4 }}
        value={value}
        onChange={(e) => onChange(e.currentTarget.value as ProgressSaveType)}
      >
        <FormControlLabel value="NOTNEEDED" control={<Radio />} label="No, the game does not need progress save" disabled={isNonEditable} />

        <FormControlLabel
          value="SDKPS"
          control={<Radio />}
          label="Yes, using the Data Module from the CrazyGames SDK"
          disabled={isNonEditable}
        />
        {value === 'SDKPS' && submission?.status === 'RELEASED' && (
          <TextAlert sx={{ marginBottom: 1 }}>
            If you start using the data module in a game that's already published, existing progress may be lost! Check the{' '}
            <a
              href={`${DOCS_URL}/sdk/${['iframe', 'html5'].includes(baseForm.gameLoaderType || '') ? 'html5-v3' : 'unity'}/data/`}
              style={{ color: COLORS.warning[100], textDecoration: 'underline' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              data module docs
            </a>{' '}
            for more info.
          </TextAlert>
        )}

        <FormControlLabel
          value="INGAMEAUTOLOGIN"
          control={<Radio />}
          label="Yes, linked to a game account on the game’s backend, which is associated with the CrazyGames User"
          disabled={isNonEditable}
        />
        {value === 'INGAMEAUTOLOGIN' && (
          <TextAlert sx={{ marginBottom: 1 }}>
            Games that don't automatically log in CrazyGames users, or games that provide external log in methods (Google, Facebook, etc...)
            may be rejected. Check{' '}
            <a
              href={`${DOCS_URL}/requirements/account-integration`}
              style={{ color: COLORS.warning[100], textDecoration: 'underline' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              account integration docs
            </a>{' '}
            for more info.
          </TextAlert>
        )}

        {unityLoadersWithDataSave.includes(gameLoaderType) && (
          <>
            <FormControlLabel
              value="PLAYERPREFS"
              control={<Radio />}
              label={
                <span>
                  Yes, in the PlayerPrefs file (refer to{' '}
                  <a href={`${DOCS_URL}/other/aps/`} target="_blank" rel="noreferrer">
                    Automatic Progress Save
                  </a>
                  )
                </span>
              }
              disabled={isNonEditable}
            />
            {gameBuild && originalProgressSaveType === 'CUSTOM' && (
              <FormControlLabel
                value="CUSTOM"
                control={<Radio />}
                label={
                  <span>
                    Yes, in a custom local file (refer to{' '}
                    <a href={`${DOCS_URL}/other/aps/`} target="_blank" rel="noreferrer">
                      Automatic Progress Save
                    </a>
                    )
                  </span>
                }
                disabled={isNonEditable}
              />
            )}
          </>
        )}

        {gameLoaderType === 'html5' && (
          <>
            <FormControlLabel
              value="LOCALSTORAGE"
              control={<Radio />}
              label={
                <span>
                  Yes, using LocalStorage (refer to{' '}
                  <a href={`${DOCS_URL}/other/aps/`} target="_blank" rel="noreferrer">
                    Automatic Progress Save
                  </a>
                  )
                </span>
              }
              disabled={isNonEditable}
            />
            {value === 'LOCALSTORAGE' && baseForm.gameLoaderType === 'html5' && (
              <Alert sx={{ marginBottom: 1 }} severity="warning">
                For iframe games our Automatic Progress Save does not work, to benefit from cloud save please use the{' '}
                <a href={`${DOCS_URL}/sdk/data`} target="_blank" rel="noreferrer" style={{ color: 'white', textDecoration: 'underline' }}>
                  data module
                </a>{' '}
                from the SDK.
              </Alert>
            )}
          </>
        )}

        {/* This is not a toggle developers can select, only QA team. But if QA team selected it, show it for the developers also. */}
        {value === 'INGAME' && (
          <FormControlLabel
            value="INGAME"
            control={<Radio />}
            label="Yes, linked to a game account on the game’s backend"
            disabled={isNonEditable}
          />
        )}
      </RadioGroup>

      {hasApsInfoMissingProblem && (
        <StyledBodyText sx={{ m: 0.5, height: 20 }} variant="bodyLower2" color="error">
          Please select 1 option
        </StyledBodyText>
      )}

      {showSDKPSNotSelectedWarning && (
        <Alert severity="warning">Your submission is using the data module, please be sure you selected the correct option.</Alert>
      )}
    </>
  );
};

export default GameDataSaveOptions;
