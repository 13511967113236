import { GameCoverWithUploadedFile, GameCoversWithUploadedFile, buildEmptyGameCovers } from './game';

export function convertGameCoversArrayToObject(array: GameCoverWithUploadedFile[] | null): GameCoversWithUploadedFile {
  const output: GameCoversWithUploadedFile = buildEmptyGameCovers();
  if (array && array.length > 0) {
    array.forEach((gameCover) => {
      output[gameCover.size] = gameCover.uploadedFile;
    });
  }
  return output;
}
