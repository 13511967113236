import React, { useCallback, useContext, useState } from 'react';
import ActionLink from '../../../../Footer/ActionLink';
import { submissionToUpdateGraphQLInput } from '../../SubmitGame/submission-helpers';
import { toast } from 'react-toastify';
import { updateSubmission } from '../Mutations/update-submission-mutation';
import { ApolloError } from '@apollo/client';
import { SubmissionContext } from '../Context/SubmissionProvider';
import GameDetailsFormLoader from '../GameDetailsForm/GameDetailsFormLoader';
import { useHistory } from 'react-router-dom';
import { getLatestBuild } from '../../../../../../common/build-utils';

interface DetailsStepProps {
  goToLegal(): void;
  onError: (error?: ApolloError) => void;
}

// Dev can land on this step after QA or after base step if no QA is needed
// Only next step is Legal, you can also go back to Draft
const DetailsStep: React.FC<DetailsStepProps> = ({ goToLegal, onError }) => {
  const { baseForm, detailsForm, detailsFormProblems, submission, setAttemptedToSave, setIsModified, modified, setBuildId } =
    useContext(SubmissionContext);
  const history = useHistory();

  const [isSaving, setIsSaving] = useState(false);

  const submit = useCallback(async () => {
    if (!detailsFormProblems && !modified) {
      goToLegal();
      return;
    }

    if (detailsFormProblems && detailsFormProblems.length > 0) {
      toast.error('Please check if all the mandatory fields marked with * are filled before continuing', { autoClose: 8000 });
      setAttemptedToSave(true);
      return;
    }

    setIsSaving(true);

    try {
      const updateInput = submissionToUpdateGraphQLInput(submission!, 'details', baseForm!, detailsForm);
      const res = await toast.promise(updateSubmission(updateInput), {
        pending: 'Updating submission...',
        success: 'Submission Updated!',
        error: 'Error updating submission',
      });
      const { gameBuilds } = res.data!.developerSubmissionUpdate;
      const latestBuild = getLatestBuild(gameBuilds);
      setBuildId(latestBuild.id, () => {
        onError();
        setIsSaving(false);
        setIsModified(false, () => {
          goToLegal();
        });
      });
    } catch (err) {
      onError(err as ApolloError);
      setIsSaving(false);
    }
  }, [modified, detailsFormProblems, goToLegal, setAttemptedToSave, submission, baseForm, detailsForm, onError, setIsModified, setBuildId]);

  return (
    <>
      <GameDetailsFormLoader />
      <ActionLink
        primaryButton={{
          title: modified ? 'Save and continue to last step' : 'Continue to last step',
          callback: submit,
        }}
        primaryButtonDisabled={isSaving}
        secondaryButton={{
          title: 'Back to Draft',
          callback: () => {
            history.replace({ search: '' }); // remove possible fromQA query param
            window.location.reload();
          },
        }}
        secondaryButtonDisabled={isSaving}
      />
    </>
  );
};

export default DetailsStep;
