import * as React from 'react';
import { StyledButton } from '../../Styleguide/Common/Button';
import { Box } from '@mui/material';
import { StyledBodyText, StyledHeaderTextH2 } from '../../Styleguide/Common/Text';
import { GameJamLink, getGameJamLink } from '../../../components/App/Content/UserDeveloper/Submission/gamejamHelper';
import { SubmissionContext } from '../../../components/App/Content/UserDeveloper/Submission/Context/SubmissionProvider';
import { generateSubmissionPortalPreviewRoute } from '../../../components/App/Content/UserDeveloper/QATool/helpers/preview-helpers';

export interface PreviewProps {}

type Props = PreviewProps;

const Preview: React.FC<Props> = () => {
  const { submission, gameBuild } = React.useContext(SubmissionContext);

  if (!submission || !gameBuild) {
    return null;
  }

  if (submission.isGameJam) {
    const link = getGameJamLink(submission.id);
    return (
      <Box sx={{ ml: 2.5, pt: 2 }}>
        <StyledHeaderTextH2 sx={{ mt: 1, mb: 0 }}>Here is your Game Jam submission link</StyledHeaderTextH2>
        <GameJamLink href={link}>{link}</GameJamLink>
      </Box>
    );
  }

  const portalLink = generateSubmissionPortalPreviewRoute(submission.id, {
    gameBuildId: gameBuild.id,
    qaTool: true,
    disableSubmitQA: true,
  });
  return (
    <Box sx={{ ml: 2.5, pt: 2 }}>
      <StyledHeaderTextH2 sx={{ mt: 1, mb: 0 }}>Preview your game</StyledHeaderTextH2>
      <StyledBodyText sx={{ mt: 1, mb: 2 }} color="white60">
        You can Preview your latest game update in our QA tool via this link. The tool shows the CrazyGames requirements and checks if your
        game complies.
      </StyledBodyText>
      <StyledButton variant="contained" sx={{ width: 'min-content' }} href={portalLink} target="_blank">
        Preview
      </StyledButton>
    </Box>
  );
};

export default Preview;
