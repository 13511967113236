import { StyledButton, StyledLoadingButton } from '../../../../../common/Styleguide/Common/Button';
import { useContext, useState } from 'react';
import { GraphQLError } from '../../../../../common/components/ApolloError/GraphQLError';
import SessionContext from '../../../../../common/Session/SessionContext';
import {
  _localUtilityCreatePartialDraftSubmission,
  _localUtilityCreateFullDraftSubmission,
  _localUtilityCreateSubmittedSubmission,
} from './utils';
import { ApolloError } from '@apollo/client';
import { StyledInput } from '../../../../../common/Styleguide/Common/Input';
import { Replay } from '@mui/icons-material';
import { StyledBodyText } from '../../../../../common/Styleguide/Common/Text';
import { COLORS } from '../../../../../common/Styleguide/Common/colors';

export const ENABLE_LOCAL_UTILITIES = window.location.host === 'localhost:3001';

const LocalUtilities: React.FC = () => {
  const [error, setError] = useState<any | undefined>(undefined);
  const [creatingDraftSubmission, setCreatingDraftSubmission] = useState(false);
  const [creatingFullNotSubmitted, setCreatingFullNotSubmitted] = useState(false);
  const [creatingSubmittedSubmission, setCreatingSubmittedSubmission] = useState(false);
  const [log, setLog] = useState<string[]>([]);
  const [name, setName] = useState<string>(new Date().toLocaleString(navigator.language));
  const session = useContext(SessionContext);

  const createDraftSubmission = async () => {
    setError(undefined);
    setCreatingDraftSubmission(true);
    try {
      const submission = await _localUtilityCreatePartialDraftSubmission(name, session);
      setLog([...log, `[Partial draft]   ${submission.id}   ${submission.slug}`]);
    } catch (e) {
      setError(e);
    } finally {
      setCreatingDraftSubmission(false);
    }
  };

  const createFullNotSubmittedSubmission = async () => {
    setError(undefined);
    setCreatingFullNotSubmitted(true);
    try {
      const submission = await _localUtilityCreateFullDraftSubmission(name, session);
      setLog([...log, `[Full draft]   ${submission.id}   ${submission.slug}`]);
    } catch (e) {
      setError(e);
    } finally {
      setCreatingFullNotSubmitted(false);
    }
  };

  const createSubmittedSubmission = async () => {
    setError(undefined);
    setCreatingSubmittedSubmission(true);
    try {
      const submission = await _localUtilityCreateSubmittedSubmission(name, session);
      setLog([...log, `[Submitted]   ${submission.id}   ${submission.slug}`]);
    } catch (e) {
      setError(e);
    } finally {
      setCreatingSubmittedSubmission(false);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16, width: 700 }}>
        <h2>Create submission</h2>
        <div>
          Name
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledInput
              sx={{ flex: 1 }}
              value={name}
              onChange={(e) => {
                setName(e.currentTarget.value);
              }}
              onBlur={(e) => {
                setName(e.currentTarget.value);
              }}
            />
            <StyledButton
              onClick={() => {
                setName(new Date().toLocaleString(navigator.language));
              }}
            >
              <Replay />
            </StyledButton>
          </div>
          <StyledBodyText variant="bodyLower" sx={{ m: 0.5, color: COLORS.white[30] }}>
            Feel free to type other name also.
          </StyledBodyText>
        </div>
        <div>
          <StyledLoadingButton loading={creatingDraftSubmission} onClick={createDraftSubmission} variant="contained" sx={{ width: 300 }}>
            Partial Draft
          </StyledLoadingButton>
          <StyledBodyText variant="bodyLower" sx={{ m: 0.5, color: COLORS.white[30] }}>
            Submission with first step completed (name, engine, files, etc). No QA tool passed yet.
          </StyledBodyText>
        </div>
        <div>
          <StyledLoadingButton
            loading={creatingFullNotSubmitted}
            onClick={createFullNotSubmittedSubmission}
            variant="contained"
            sx={{ width: 300 }}
          >
            Full Draft
          </StyledLoadingButton>
          <StyledBodyText variant="bodyLower" sx={{ m: 0.5, color: COLORS.white[30] }}>
            Passed QA, has covers, description, name, etc. Needs legal requirements to be completed.
          </StyledBodyText>
        </div>
        <div>
          <StyledLoadingButton
            loading={creatingSubmittedSubmission}
            onClick={createSubmittedSubmission}
            variant="contained"
            sx={{ width: 300 }}
          >
            Submitted
          </StyledLoadingButton>
          <StyledBodyText variant="bodyLower" sx={{ m: 0.5, color: COLORS.white[30] }}>
            Passed QA, has covers, description, name, legal form completed and submitted, needs QA review now.
          </StyledBodyText>
        </div>
      </div>
      {error && error instanceof ApolloError && <GraphQLError error={error} />}
      {error && !(error instanceof ApolloError) && <div>{error.toString()}</div>}
      {log.length > 0 && <h2>Log</h2>}
      <div style={{ marginTop: 24 }}>
        {log.map((l) => (
          <div key={l}>{l}</div>
        ))}
      </div>
    </>
  );
};

export default LocalUtilities;
