import { TranslatedEntry, TranslationMap } from '../../i18n';
import { PREROLL_SETTING } from '../../../components/App/Content/Admin/Game/Game';
import { SeoStatusesWithLocale } from '../../../components/App/Content/Admin/SeoStatuses/SeoStatuses.helper';
import { UploadedFile } from '../../domain/upload';
import { UploadedFileInput } from '../upload-input';
import { GameBuild } from '../../../components/App/Content/Admin/Submission/graphql/game-build-graphql';
import { GameLoaderType } from '../../domain/game';
import { MultiplayerOptions } from '../../../components/App/Content/UserDeveloper/Submission/GameBaseForm/baseFormHelper';

export type GAME_STATUS = 'DRAFT' | 'PUBLISHED' | 'ARCHIVED' | 'UNAVAILABLE';
export const GAME_STATUSES: GAME_STATUS[] = ['DRAFT', 'PUBLISHED', 'ARCHIVED', 'UNAVAILABLE'];

export type GAME_EDITOR_STATUS = 'NEEDS_VIDEO' | 'NEEDS_FULL_REVIEW' | 'NEEDS_DESCRIPTION' | 'OK';
export const GAME_EDITOR_STATUSES: GAME_EDITOR_STATUS[] = ['NEEDS_VIDEO', 'NEEDS_FULL_REVIEW', 'NEEDS_DESCRIPTION', 'OK'];

export type GAME_BUSINESS_TIER = 'TIER_1' | 'TIER_2' | 'TIER_3' | 'THIRD_PARTY' | 'BLANK' | 'NO_TIER' | 'CRAZYGAMES_IP' | 'LICENSED';
export const GAME_BUSINESS_TIERS: GAME_BUSINESS_TIER[] = [
  'TIER_1',
  'TIER_2',
  'TIER_3',
  'THIRD_PARTY',
  'BLANK',
  'NO_TIER',
  'CRAZYGAMES_IP',
  'LICENSED',
];

export interface GameVideoSize {
  location: string;
  width: number;
  height: number;
}

export interface BusinessDataDeveloper {
  id: string;
  email: string;
  name: string;
  publicProfile?: {
    displayName: string;
  };
}

/** Contains video displayed on portal. When a game is created initially in SUBMISSION status all these values will be null. */
export interface GameVideo {
  /** Original s3 location for landscape video */
  original: string | null;
  /** Original s3 location for portrait video */
  portraitOriginal: string | null;
  /** Various video sizes for landscape video, portal picks the best based on resolution */
  sizes: GameVideoSize[];
  /** Various video sizes for portrait video, portal picks the best based on resolution */
  portraitSizes: GameVideoSize[];
  /** Blurred video displayed on gameframe behind the "Play now" button */
  blurredVideo: GameVideoSize | null;
}

export interface SubmissionVideo {
  videoThumbnail: string | null;
  videoOriginal: string | null;
  portraitVideoThumbnail: string | null;
  portraitVideoOriginal: string | null;
}

export function buildEmptySubmissionVideo(): SubmissionVideo {
  return {
    videoThumbnail: null,
    videoOriginal: null,
    portraitVideoThumbnail: null,
    portraitVideoOriginal: null,
  };
}

export type GAME_COVERS_RESOLUTIONS = '16x9' | '2x3' | '1x1';

export function buildEmptyGameCovers() {
  return {
    '16x9': null,
    '2x3': null,
    '1x1': null,
  };
}

export const GAME_COVERS_DEFAULT_ARRAY = [
  { size: '16x9' as GAME_COVERS_RESOLUTIONS, uploadedFile: null },
  { size: '2x3' as GAME_COVERS_RESOLUTIONS, uploadedFile: null },
  { size: '1x1' as GAME_COVERS_RESOLUTIONS, uploadedFile: null },
];

export type GameCovers = {
  [K in GAME_COVERS_RESOLUTIONS]: string | null;
};

export type GameCoversWithUploadedFile = {
  [K in GAME_COVERS_RESOLUTIONS]: UploadedFile | null;
};

export interface GameCoverWithUploadedFile {
  size: GAME_COVERS_RESOLUTIONS;
  uploadedFile: UploadedFile | null;
}

export interface GameCoverWithUploadedFileInput {
  size: GAME_COVERS_RESOLUTIONS;
  uploadedFile: UploadedFileInput | null;
}

export interface ExternalLinksEntries {
  wikipedia: TranslatedEntry[];
  fandom: TranslatedEntry[];
}
export interface ExternalLinksMap {
  wikipedia: TranslationMap;
  fandom: TranslationMap;
}

export interface GameData {
  id: string;
  slug: string;
  name: TranslatedEntry[];
  cover: string;
  coverUrl: string;

  rawGameCoversUrls: string;

  description: TranslatedEntry[];
  controls: TranslatedEntry[];
  title: TranslatedEntry[];
  metaDescription: TranslatedEntry[];
  customStructuredData: TranslatedEntry[];
  externalLinks: ExternalLinksEntries;
  rawCustomFields: string;
  rawFaq: string;
  rawVideoStructuredData: string | null;
  gameSupportUrl: string | null;

  status: GAME_STATUS;
  editorStatus: GAME_EDITOR_STATUS;
  addedOn: string | null;
  createdOn: string | null;
  forcedFeaturedNew: string | null;
  forcedFeaturedNewStart: string | null;

  categoryId: string;
  collection: string | null;
  tagIds: string[];

  loaderType: GameLoaderType;
  loaderConfigJson: string;
  scoreBonus: number | null;
  temporaryScoreBonus: number | null;
  temporaryScoreBonusUntil: string | null;
  mobileScoreBonus: number | null;
  temporaryMobileScoreBonus: number | null;
  temporaryMobileScoreBonusUntil: string | null;

  rawVideo: string | null;
  iosFriendly: boolean;
  androidFriendly: boolean;
  isEligibleForMobileHomepage: boolean;
  chromebookFriendly: boolean;
  appstoreUrl: string | null;
  steamstoreUrl: string | null;
  playstoreUrl: string | null;
  developer: string | null;
  user: BusinessDataDeveloper | null;
  series: string;
  rawAlternative: string | null;
  rawControlsJson: string | null;
  rawGameframeConfig: string;

  hasVeedi: boolean;
  allowEmbed: boolean;
  sandbox: boolean;
  isDownloadable?: boolean;
  rawPortalConfig: string | null;
  shareEmail: boolean;

  submissionId: string | null;
  preroll: PREROLL_SETTING | null;
  seoStatuses: SeoStatusesWithLocale;
  s3Location: string | null;
  size: number | null;
  isKids: boolean;
  isOriginal: boolean;
  forceOriginalFrom: string | null;
  forceOriginalUntil: string | null;
  forceOriginalPosition: number | null;
  businessTier: GAME_BUSINESS_TIER;
  lastSignificantUpdatedOn: string | null;
  rawThumbLabelOverride: string | null;
  boostLinking: boolean;
  isRecommendedNextWeek: boolean;
  isDevHidden: boolean;
  isHiddenOnHomePage: boolean;
  isHiddenOnPortal: boolean;
  staffPickedUntil: string | null;
  staffPickedFrom: string | null;
  staffPickedWeight: number | null;
  seoKeyword: string | null;
  multiplayerOptions: MultiplayerOptions | null;

  exclusiveUntil: string | null;
  exclusiveAutoRenew: boolean;

  isIap: boolean;

  gameBuilds: GameBuild[];
}
