import { Theme, styled } from '@mui/material';
import { COLORS } from '../../../../common/Styleguide/Common/colors';

export const Root = styled('div')(({ theme: { breakpoints } }: { theme: Theme }) => ({
  '& h1': {
    fontSize: 80,
    fontWeight: 800,
    color: COLORS.white[100],
    lineHeight: '100%',
    marginBlock: 0,
    [breakpoints.down(1750)]: {
      fontSize: 70,
    },
    [breakpoints.down(1400)]: {
      fontSize: 60,
    },
    [breakpoints.down('md')]: {
      fontSize: 32,
      lineHeight: '130%',
    },
  },
  '& h2': {
    fontSize: 28,
    fontWeight: 800,
    marginBlock: 0,
    lineHeight: '130%',
    color: COLORS.white[100],
    [breakpoints.up(1400)]: {
      fontSize: 48,
    },
    [breakpoints.between('md', 1400)]: {
      fontSize: 38,
    },
  },
  '& h3': {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '130%',
    marginBlock: 0,
    color: COLORS.white[100],
  },
  '& h4': {
    fontSize: 24,
    fontWeight: 400,
    color: COLORS.white[60],
    lineHeight: '130%',
    marginBlock: 0,
    [breakpoints.down(1750)]: {
      fontSize: 20,
      lineHeight: '135%',
    },
  },
  '& h5': {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '135%',
    marginBlock: 0,
  },
  '& p': {
    fontSize: 16,
    marginBlock: 0,
    lineHeight: '135%',
  },
  '& a.underlinedAnchor': {
    color: COLORS.white[80],
    textDecoration: 'underline',
    '&:hover': {
      color: COLORS.white[60],
    },
  },
  '& button': {
    '& svg': {
      marginRight: 0,
    },
  },
  '& section.purpleSection': {
    '& p, h4': {
      color: COLORS.brand[20],
    },
  },
  '& section.blackSection': {
    '& p, h4': {
      color: COLORS.white[60],
    },
  },
}));

export const Section2List = styled('div')(({ theme: { breakpoints } }: { theme: Theme }) => ({
  display: 'flex',
  marginBottom: 25,
  alignItems: 'center',
  '& svg': {
    width: 60,
    height: 60,
    marginRight: 20,
    [breakpoints.down('sm')]: {
      width: 40,
      height: 40,
    },
  },
}));

export const SectionHorList = styled('div')(({ theme: { breakpoints, spacing } }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 36px',
  [breakpoints.down('md')]: {
    marginTop: spacing(4),
  },
  '& svg': {
    width: 60,
    height: 60,
    color: COLORS.brand[100],
    marginBottom: spacing(1),
  },
  '& h3': {
    marginBottom: 15,
  },
  '& p': {
    maxWidth: 300,
  },
}));

interface LiberatedGameProps {
  gamePosition?: number;
}

export const LiberatedGame = styled('div', {
  shouldForwardProp: (prop) => prop !== 'gamePosition',
})<LiberatedGameProps>(({ theme: { breakpoints, spacing }, gamePosition }: { theme: Theme; gamePosition?: number }) => ({
  background: '#fff',
  borderRadius: 20,
  padding: spacing(1.25),
  display: 'flex',
  flexDirection: 'column',
  marginBottom: spacing(2.5),
  width: '286px',
  [breakpoints.down('md')]: {
    flexDirection: 'column',
    marginBottom: spacing(2.5),
  },
  [breakpoints.up('md')]: {
    ...(gamePosition === 1
      ? {
          position: 'absolute',
          right: '-230px',
          top: '140px',
        }
      : {}),
    ...(gamePosition === 2
      ? {
          marginLeft: '-30px',
        }
      : {}),
  },
  '& img': {
    borderRadius: 10,
  },
  '& .liberatedContainer': {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: COLORS.black[100],
    marginTop: spacing(1.5),
    fontSize: 16,
  },
  '& .liberatedTitle': {
    fontSize: 18,
    fontWeight: 900,
  },
}));

export const LiberatedGameImg = styled('img')({
  height: 150,
  width: 268,
});

export const StyledTestimonialGrid = styled('div')(({ theme: { breakpoints, spacing } }: { theme: Theme }) => ({
  margin: spacing(2.5),
  columns: '300px',

  [breakpoints.down('md')]: {
    position: 'relative',
    paddingBottom: spacing(6),
    paddingTop: spacing(5),
  },
  [breakpoints.up('md')]: {
    marginRight: 100,
    marginLeft: 100,
    columns: '300px',
  },
  [breakpoints.up(1450)]: {
    marginRight: '18vw',
    marginLeft: '18vw',
  },
}));

export const TestimonialSlide = styled('div')(({ theme: { spacing } }: { theme: Theme }) => ({
  color: 'white',
  background: COLORS.black[80],
  borderRadius: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginBottom: spacing(2.5),
  padding: spacing(4),
  columnBreakInside: 'avoid',
  pageBreakInside: 'avoid',
  breakInside: 'avoid',
  '& img': {
    width: 70,
    height: 70,
  },
  '& .testimonialAuthor': {
    fontWeight: 700,
    color: COLORS.white[100],
    marginTop: spacing(2),
  },
  '& .testimonialOrg': { color: COLORS.white[100], textAlign: 'left', marginBottom: spacing(2) },
}));

export const TestimonialText = styled('div')(() => ({
  textAlign: 'left',
  overflowY: 'scroll',
  color: COLORS.white[60],
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  flex: 1,

  '&::-webkit-scrollbar': {
    width: 3,
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: COLORS.white[60],
    borderRadius: 2,
  },
}));
