import { gql } from '@apollo/client';
import { mutate } from '../../../../../../common/graphql';
import { GET_MY_SUBMISSION_QUERY } from '../submission-graphql';
import { QAResult } from '../../QATool/helpers/qa-result-types';

const getSubmissionMutationInvalidations = (id: string) => [{ query: GET_MY_SUBMISSION_QUERY, variables: { id: id } }];

interface DeveloperSubmissionAddQAResultAndNoteInput {
  id: string;
  gameBuildId: string;
  qaResult: QAResult;
  updateNoteText: string;
}

const UPDATE_SUBMISSION_QA_RESULT = gql`
  mutation submissionAddQAResultAndNote($input: DeveloperSubmissionAddQAResultAndNoteInput!) {
    developerSubmissionAddQAResultAndNote(input: $input) {
      id
    }
  }
`;

interface SubmissionAddQAResultAndNoteMutation {
  developerSubmissionAddQAResult: SubmissionAddQAResultAndNoteResult;
}

export interface SubmissionAddQAResultAndNoteResult {
  id: string;
}

export function updateSubmissionAddQAResultAndNote(input: DeveloperSubmissionAddQAResultAndNoteInput) {
  return mutate<DeveloperSubmissionAddQAResultAndNoteInput, SubmissionAddQAResultAndNoteMutation>(UPDATE_SUBMISSION_QA_RESULT, input, {
    refetchQueries: getSubmissionMutationInvalidations(input.id),
  });
}
