import logo from '../../../../common/img/game-jam-logo.svg';
import cloudsLeft from '../../../../common/img/graphics-left.svg';
import cloudsRight from '../../../../common/img/graphics-right.svg';
import { StyledButton } from '../../../../common/Styleguide/Common/Button';
import styled from '@emotion/styled';

export function shouldShowGameJamBanner() {
  if (window.location.href.includes('http://localhost:3001')) {
    return true;
  }

  const currentTime = new Date();

  // Start date: March 10, 10:00 AM CEST
  const startDate = new Date('2025-03-10T08:00:00Z');

  // End date: May 5, 12:00 PM (midnight) CEST
  const endDate = new Date('2025-05-05T22:00:00Z');

  return currentTime >= startDate && currentTime <= endDate;
}

const StyledGameJamBanner = styled('div')({
  '@media (max-width: 900px)': {
    display: 'none',
  },
  height: `100px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 36,
  position: 'relative',
  '.graphics': {
    position: 'absolute',
    height: '100%',
  },
  '@media (max-width: 1770px)': {
    '.graphics': {
      display: 'none',
    },
  },
  '@media (max-width: 1100px)': {
    gap: 12,
    '.join-button': {
      display: 'none',
    },
  },
  background: `linear-gradient(90deg, #021024 0%, #32144F 57%, #12093A 100%)`,
});

export const GameJamBanner: React.FC = () => {
  return (
    <StyledGameJamBanner>
      <img className="graphics" style={{ left: 0 }} src={cloudsLeft} alt={'graphics'} />
      <img className="graphics" style={{ right: 0 }} src={cloudsRight} alt={'graphics'} />
      <img src={logo} style={{ height: 68, zIndex: 1 }} alt="Game Jam" />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 1 }}>
        <div
          style={{
            fontFamily: 'Montserrat',
            fontSize: '26px',
            fontWeight: 700,
            color: '#fff',
          }}
        >
          PARTICIPATE NOW
        </div>
        <a
          href="https://jam.crazygames.com"
          style={{
            fontFamily: 'Montserrat',
            fontSize: '16px',
            fontWeight: 700,
            color: '#E7DD21',
            textDecoration: 'none',
          }}
          target="_blank"
          rel="noreferrer"
        >
          JAM.CRAZYGAMES.COM
        </a>
      </div>
      <div className="join-button" style={{ width: 192, zIndex: 1 }}>
        <StyledButton
          color="yellow"
          variant="contained"
          height={48}
          fullWidth
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '16px',
            fontWeight: 700,
            color: '#140D34',
          }}
          onClick={() => window.open('https://jam.crazygames.com', '_blank')}
        >
          LEARN MORE
        </StyledButton>
      </div>
    </StyledGameJamBanner>
  );
};
