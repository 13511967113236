import { SxProps } from '@mui/material';
import * as React from 'react';
import { COLORS } from './colors';
import { StyledContainer } from './Container';
import { StyledHeaderText } from './Text';

export type AlertColor = 'green' | 'red' | 'orange' | 'purple';

interface AlertProps {
  children: React.ReactNode;
  sx?: SxProps;
  color?: AlertColor;
  title?: string | React.ReactNode;
}

const Alert: React.FunctionComponent<AlertProps> = ({ children, title, color = 'purple' }) => {
  const getBorderColor = () => {
    switch (color) {
      case 'green':
        return COLORS.success[100];
      case 'red':
        return COLORS.alert[100];
      case 'orange':
        return COLORS.warning[100];
      case 'purple':
      default:
        return COLORS.brand[100];
    }
  };
  return (
    <StyledContainer
      sx={{
        width: 900,
        m: 2,
        p: 2,
        px: 3,
        pb: 4,
        color: COLORS.white[60],
        borderTop: `4px solid ${getBorderColor()}`,
        background: COLORS.black[70],
      }}
    >
      {title && (
        <StyledHeaderText variant="h2" sx={{ mt: 0, mb: 1, display: 'flex' }}>
          {title}
        </StyledHeaderText>
      )}
      {children}
    </StyledContainer>
  );
};

export default Alert;
