import React from 'react';
import { Alert } from '@mui/material';
import { GameLoaderType, unityLoadersWithCompression } from '../../../../../../../common/domain/game';
import { Unity56Encoding } from './UnityCompressionSelect';
import { UploadedFile } from '../../../../../../../common/domain/upload';
import TextAlert from '../../../TextAlert';

export interface UnityCompressionWarningsProps {
  gameLoaderType: GameLoaderType | null;
  unity56Encoding?: Unity56Encoding;
  files: UploadedFile[] | null;
}

const UnityCompressionWarnings: React.FC<UnityCompressionWarningsProps> = (props) => {
  const { gameLoaderType, unity56Encoding, files } = props;

  if (!gameLoaderType || !unityLoadersWithCompression.includes(gameLoaderType)) {
    return null;
  }

  let possibleEncoding: Unity56Encoding | undefined;
  const gzipFilesCount = files?.filter((f) => f.path.endsWith('.gz') || f.path.endsWith('.gzip')).length || 0;
  const brFilesCount = files?.filter((f) => f.path.endsWith('.br')).length || 0;
  // at the moment Unity has 3 files compressed in the final build. In case they remove some files in the future, take into considerations at least 2 files.
  if (gzipFilesCount > 1) {
    possibleEncoding = 'gzip';
  }
  if (brFilesCount > 1) {
    possibleEncoding = 'brotli';
  }

  return (
    <>
      {unity56Encoding === 'gzip' && (
        <TextAlert sx={{ marginBottom: 1 }}>
          We recommend exporting your WebGL build with Brotli compression, which is ~12% smaller than Gzip.
        </TextAlert>
      )}
      {!possibleEncoding && files && files.length > 0 && (
        <Alert sx={{ marginBottom: 1 }} severity="info">
          Compression could not be detected automatically, please be sure you select the correct one.
        </Alert>
      )}
      {possibleEncoding && props.unity56Encoding && possibleEncoding !== props.unity56Encoding && (
        <Alert sx={{ marginBottom: 1 }} severity="info">
          Please be sure the selected compression format matches the compression format of your files. Check the{' '}
          <a style={{ color: 'inherit' }} href="https://docs.unity3d.com/Manual/webgl-deploying.html">
            Unity documentation
          </a>{' '}
          for more details.
        </Alert>
      )}
    </>
  );
};

export default UnityCompressionWarnings;
