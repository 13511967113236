import { useContext } from 'react';
import { SubmissionContext } from '../Context/SubmissionProvider';
import { Checkbox, FormControlLabel } from '@mui/material';
import { DOCS_URL } from '../../../../SideMenu/Menu';
import { StyledBodyText } from '../../../../../../common/Styleguide/Common/Text';
import { StyledInput } from '../../../../../../common/Styleguide/Common/Input';
import { MAX_LOBBY_SIZE, MIN_LOBBY_SIZE } from './baseFormHelper';
import TextAlert from '../../TextAlert';
import { COLORS } from '../../../../../../common/Styleguide/Common/colors';

const MultiplayerLobbyEditor: React.FC = () => {
  const { baseForm, baseFormProblems, updateBaseForm, isNonEditable, isReleased } = useContext(SubmissionContext);

  // we don't currently allow modifying multiplayer stuff for released games, this may change once multiplayer flags are moved to game
  const editDisabled = isReleased || isNonEditable;

  if (baseForm.gameLoaderType === null || !baseForm?.multiplayerOptions?.isMultiplayer) {
    return null;
  }

  const renderMultiplayerOptions = () => {
    const onSupportsInstantJoinChange = (supportsInstantJoin: boolean) => {
      updateBaseForm({
        ...baseForm,
        multiplayerOptions: {
          ...baseForm.multiplayerOptions,
          supportsInstantJoin,
        },
      });
    };

    const onHasModeratedChatChange = (hasModeratedChat: boolean) => {
      updateBaseForm({
        ...baseForm,
        multiplayerOptions: {
          ...baseForm.multiplayerOptions,
          hasModeratedChat,
        },
      });
    };

    const onMinLobbySizeChange = (minLobbySizeValue: string) => {
      const minLobbySize = parseInt(minLobbySizeValue);
      updateBaseForm({
        ...baseForm,
        multiplayerOptions: {
          ...baseForm.multiplayerOptions,
          minLobbySize: isNaN(minLobbySize) ? undefined : minLobbySize,
        },
      });
    };

    const onMaxLobbySizeChange = (maxLobbySizeValue: string) => {
      const maxLobbySize = parseInt(maxLobbySizeValue);
      updateBaseForm({
        ...baseForm,
        multiplayerOptions: {
          ...baseForm.multiplayerOptions,
          maxLobbySize: isNaN(maxLobbySize) ? undefined : maxLobbySize,
        },
      });
    };

    return (
      <>
        <StyledBodyText
          variant="h2"
          sx={{
            pb: 1,
            m: 0,
          }}
        >
          Multiplayer options
        </StyledBodyText>
        <FormControlLabel
          label="The game supports IsInstantMultiplayer flag"
          disabled={editDisabled}
          control={
            <Checkbox
              checked={!!baseForm.multiplayerOptions?.supportsInstantJoin}
              onChange={(e) => onSupportsInstantJoinChange(e.currentTarget.checked)}
            />
          }
        />
        <FormControlLabel
          label="My game has chat functionality with a profanity filter and/or moderation."
          disabled={editDisabled}
          control={
            <Checkbox
              checked={!!baseForm.multiplayerOptions?.hasModeratedChat}
              onChange={(e) => onHasModeratedChatChange(e.currentTarget.checked)}
            />
          }
        />
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <StyledBodyText variant="h3">Lobby size:</StyledBodyText>
          Min
          <StyledInput
            width={64}
            height={32}
            disabled={editDisabled}
            value={baseForm.multiplayerOptions?.minLobbySize ?? ''}
            onChange={(e) => onMinLobbySizeChange(e.currentTarget.value)}
          />
          Max
          <StyledInput
            width={64}
            height={32}
            disabled={editDisabled}
            value={baseForm.multiplayerOptions?.maxLobbySize ?? ''}
            onChange={(e) => onMaxLobbySizeChange(e.currentTarget.value)}
          />
        </div>
        {baseFormProblems?.includes('MULTIPLAYER_INVALID_LOBBY') && (
          <StyledBodyText color="alert60" sx={{ marginBlock: 0 }}>
            Please fill both lobby size fields correctly
          </StyledBodyText>
        )}
        {baseFormProblems?.includes('MULTIPLAYER_INVALID_LOBBY_MIN') && (
          <StyledBodyText color="alert60" sx={{ marginBlock: 0 }}>
            Minimum lobby size must be at least {MIN_LOBBY_SIZE}
          </StyledBodyText>
        )}
        {baseFormProblems?.includes('MULTIPLAYER_INVALID_LOBBY_MAX') && (
          <StyledBodyText color="alert60" sx={{ marginBlock: 0 }}>
            Maximum lobby size must be lower than {MAX_LOBBY_SIZE}
          </StyledBodyText>
        )}
        <TextAlert>
          We have specific requirements for multiplayer games, please refer to our{' '}
          <a
            href={`${DOCS_URL}/requirements/multiplayer/`}
            target="_blank"
            rel="noreferrer"
            style={{ color: COLORS.warning[100], textDecoration: 'underline' }}
          >
            documentation
          </a>{' '}
          . Submissions will be rejected if they don't comply to these requirements:
          <ul>
            <li>The game should use invite link & button functionality</li>
            <li>
              The game supports{' '}
              <a
                href={`${DOCS_URL}/sdk/game/#instant-multiplayer`}
                style={{ color: COLORS.warning[100], textDecoration: 'underline' }}
                target="blank"
              >
                IsInstantMultiplayer
              </a>{' '}
              so party leaders can create/join a lobby directly
            </li>
          </ul>
        </TextAlert>
        {isReleased && (
          <TextAlert>
            It is not possible at the moment to change the multiplayer options for released games. Please contact us at
            submissions@crazygames.com if you would like to change them.
          </TextAlert>
        )}
      </>
    );
  };

  return <>{renderMultiplayerOptions()}</>;
};

export default MultiplayerLobbyEditor;
