const DEFAULT_COLORS = {
  brand: {
    0: '#fff',
    20: '#E1D9FF',
    30: '#D2C6FF',
    40: '#C3B3FF',
    60: '#A48EFF',
    70: '#957BFF',
    80: '#8668FF',
    100: '#6842FF',
    110: '#5E3BE6',
    120: '#5335CC',
    140: '#3E2899',
    160: '#2A1A66',
    180: '#150D33',
    200: '#07050F',
  },
  black: {
    10: '#474967',
    20: '#434561',
    30: '#3F415C',
    40: '#373952',
    50: '#2F3148',
    60: '#28293D',
    70: '#212233',
    80: '#1A1B28',
    90: '#13141E',
    100: '#0C0D14',
  },
  white: {
    10: '#666A7F',
    20: '#767A8E',
    30: '#878A9E',
    40: '#989BAE',
    50: '#AAADBE',
    60: '#BDBFCE',
    70: '#D0D2DE',
    80: '#E5E6EE',
    90: '#EFF0F7',
    100: '#F9FAFF',
  },
  success: {
    100: '#4AF0A7',
  },
  alert: {
    100: '#E70D5C',
    60: '#F16E9D',
  },
  warning: {
    100: '#FFAC4A',
  },
};

export const COLORS = DEFAULT_COLORS;
