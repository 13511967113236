import { StyledBodyText } from '../../../../common/Styleguide/Common/Text';
import { SxProps } from '@mui/system';
import { PropsWithChildren } from 'react';

interface TextAlertProps {
  children: React.ReactNode;
  sx?: SxProps;
}

const TextAlert: React.FC<TextAlertProps> = ({ children, sx }: PropsWithChildren<TextAlertProps>) => {
  return (
    <StyledBodyText variant="bodyLower" sx={{ marginBlock: 0, ...sx }} color="warning">
      {children}
    </StyledBodyText>
  );
};

export default TextAlert;
