import { gql } from '@apollo/client';
import { mutate } from '../../../../../common/graphql/apollo-client';
import { SubmissionUnity56Encoding } from '../Submission/submission-graphql';
import { UploadedFileInput } from '../../../../../common/graphql/upload-input';
import { GAME_ORIENTATION } from '../../../../../common/CzyForm/OrientationSelector';
import { MY_SUBMISSIONS_QUERY } from '../Games/userDeveloperSubmission-graphql';
import { MultiplayerOptions, ProgressSaveType } from '../Submission/GameBaseForm/baseFormHelper';
import { GameLoaderType } from '../../../../../common/domain/game';
import { BUILDS_FRAGMENT, GameBuild } from '../../Admin/Submission/graphql/game-build-graphql';
import { FULLSCREEN_SETTING } from '../../Admin/Game/GameframeConfig/GameframeConfig';

export interface SubmissionAPSDetail {
  progressType: ProgressSaveType;
}

const SUBMIT_GAME_MUTATION = gql`
  mutation createNewGameSubmission($input: DeveloperSubmissionCreateInput!) {
    developerSubmissionCreate(input: $input) {
      id
      slug
      gameBuilds {
        ...GameBuildFragment
      }
    }
  }
  ${BUILDS_FRAGMENT}
`;

export interface SubmitGameMutation {
  developerSubmissionCreate: {
    id: string;
    slug: string;
    gameBuilds: GameBuild[];
  };
}

export interface DeveloperSubmissionCreateInput {
  gameName: string;
  gameFiles: UploadedFileInput[];
  iframeLink?: string;
  fullscreen: FULLSCREEN_SETTING;
  isIOSFriendly: boolean;
  isAndroidFriendly: boolean;
  orientation: GAME_ORIENTATION | null;
  gameLoaderType: GameLoaderType;
  unity56Encoding?: SubmissionUnity56Encoding;
  unitySaveFileNames?: string[];
  apsDetail: SubmissionAPSDetail;
  hasIAP: boolean;
  isChromebookFriendly: boolean;
  multiplayerOptions?: MultiplayerOptions;
  isGameJam?: boolean;
  invitationId?: string;
}

const SUBMIT_GAME_MUTATION_INVALIDATIONS = [{ query: MY_SUBMISSIONS_QUERY }];

export function submitGame(input: DeveloperSubmissionCreateInput) {
  return mutate<DeveloperSubmissionCreateInput, SubmitGameMutation>(SUBMIT_GAME_MUTATION, input, {
    refetchQueries: SUBMIT_GAME_MUTATION_INVALIDATIONS,
  });
}
