import { ClickAwayListener } from '@mui/base';
import { Box } from '@mui/system';
import { getAuth } from 'firebase/auth';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { apolloClient } from '../../../common/graphql';
import ProfileIcon from '../../../common/icons/ProfileIcon';
import { AccountPopper, AccountPopperContent, AccountCircleStyled, AccountPopperOption } from './AccountCircle.styles';

const AccountCircle: React.FunctionComponent = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();
  const open = !!anchorEl;

  const onAccountCircleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const closePopper = () => {
    setAnchorEl(null);
  };

  const onEditProfileClick = () => {
    history.push('/profile');
    closePopper();
  };

  const onSignOutClick = async () => {
    await getAuth().signOut();
    await apolloClient.resetStore();
    // reload everything to be sure everything else is cleared
    window.location.href = window.location.origin;
  };

  return (
    <ClickAwayListener onClickAway={closePopper}>
      <Box>
        <AccountCircleStyled type="button" onClick={onAccountCircleClick}>
          <ProfileIcon />
        </AccountCircleStyled>
        <AccountPopper open={open} anchorEl={anchorEl}>
          <AccountPopperContent>
            <AccountPopperOption onClick={onEditProfileClick}>Edit profile</AccountPopperOption>
            <AccountPopperOption onClick={onSignOutClick}>Sign out</AccountPopperOption>
          </AccountPopperContent>
        </AccountPopper>
      </Box>
    </ClickAwayListener>
  );
};

export default AccountCircle;
