// ENUMS ------------------------------------------------------------

export enum QANextStep {
  REAPPROVAL = 'reapproval',
  SYNC = 'sync',
  UPDATE_COMPLETE = 'updateComplete',
}

// SDK ------------------------------------------------------------

type SDKType = 'unity5.6' | 'js' | 'unity5.6-legacy' | 'unity-lite';

interface SDKInfo {
  sdkType: SDKType;
  version: string;
}

// REQUIREMENTS ------------------------------------------------------------

export type RequirementState = 'pending' | 'ok' | 'fail';

export type YesNoRequirementState = 'triggered' | RequirementState;

export type MainRequirement = 'isLoading' | 'displaysCorrectly';
export const mainRequirementsArray: MainRequirement[] = ['isLoading', 'displaysCorrectly'];

export const mainRequirementsText: { [key in MainRequirement]: string } = {
  isLoading: 'The game loads correctly',
  displaysCorrectly: 'The game displays correctly',
};

export type SDKFeatures = 'sdkInit';
export type SDKYesNoFeatures =
  | 'happytime'
  | 'gameplayStart'
  | 'gameplayStop'
  | 'sdkGameLoadingStart'
  | 'sdkGameLoadingStop'
  | 'rewardedAd'
  | 'midgameAd'
  | 'inviteLink'
  | 'banner'
  | 'useUserModule'
  | 'requestXsollaUserToken'
  | 'useDataModule'
  | 'requestUserToken';

export const sdkFeaturesArray: SDKFeatures[] = ['sdkInit'];
export const sdkYesNoFeaturesArray: SDKYesNoFeatures[] = [
  'happytime',
  'gameplayStart',
  'gameplayStop',
  'sdkGameLoadingStart',
  'sdkGameLoadingStop',
  'rewardedAd',
  'midgameAd',
  'inviteLink',
  'banner',
  'useUserModule',
  'requestUserToken',
  'requestXsollaUserToken',
  'useDataModule',
];

export const sdkAdsFeatures: SDKYesNoFeatures[] = ['rewardedAd', 'midgameAd', 'banner'];

type RequirementText = {
  pendingText: string;
  name: string;
  /** SDK events that trigger this requirement. Since these events are also forwarded through GF, some of them don't exactly match the SDK event names, like banner for example. */
  triggerdBy: string[];
};

type YesNoRequirementText = {
  questionText: string;
} & RequirementText;

export const sdkRequirementsText: { [key in SDKFeatures]: RequirementText } = {
  sdkInit: {
    pendingText: 'SDK has been initialized',
    name: 'SDK Initialization',
    triggerdBy: ['sdkInit'],
  },
};

export const sdkYesNnRequirementsText: { [key in SDKYesNoFeatures]: YesNoRequirementText } = {
  happytime: {
    pendingText: 'A happy time has been triggered',
    questionText: 'Did the happy time occur at the right time?',
    name: 'Happy time',
    triggerdBy: ['happytime'],
  },
  gameplayStart: {
    pendingText: 'A gameplay start event has been triggered',
    questionText: 'Did the gameplay start event occur at the right time?',
    name: 'Gameplay start',
    triggerdBy: ['gameplayStart'],
  },
  gameplayStop: {
    pendingText: 'A gameplay stop event has been triggered',
    questionText: 'Did the gameplay stop event occur at the right time?',
    name: 'Gameplay stop',
    triggerdBy: ['gameplayStop'],
  },
  sdkGameLoadingStart: {
    pendingText: 'A loading event has been triggered',
    questionText: 'Did the loading start event occur at the right time?',
    name: 'Loading event starts',
    triggerdBy: ['sdkGameLoadingStart'],
  },
  sdkGameLoadingStop: {
    pendingText: 'A loading event has stopped',
    questionText: 'Did the loading stop event occur at the right time?',
    name: 'Loading event stops',
    triggerdBy: ['sdkGameLoadingStop'],
  },
  rewardedAd: {
    pendingText: 'A rewarded ad has been requested',
    questionText: 'Did the rewarded ad occur at the right time?',
    name: 'Rewarded ad',
    triggerdBy: ['rewardedAd'],
  },
  midgameAd: {
    pendingText: 'A midgame ad has been requested',
    questionText: 'Did the midgame ad occur at the right time?',
    name: 'Midgame ad',
    triggerdBy: ['midgameAd'],
  },
  inviteLink: {
    pendingText: 'An invite link has been requested',
    questionText: 'Was the invite link correctly generated?',
    name: 'Invite link',
    triggerdBy: ['inviteLink'],
  },
  banner: {
    pendingText: 'A banner has been requested',
    questionText: 'Was the banner correctly displayed?',
    name: 'Banner',
    triggerdBy: ['responsiveBanner', 'banner'],
  },
  useUserModule: {
    name: 'User module',
    pendingText: 'User module has been used',
    questionText: 'Was the user module correctly used?',
    triggerdBy: [
      'isUserAccountAvailable',
      'getSystemInfo',
      'showAuthPrompt',
      'showAccountLinkPrompt',
      'getUser',
      'requestUserToken',
      'addScore',
      'addAuthListener',
      'removeAuthListener',
      'requestXsollaUserToken',
    ],
  },
  requestUserToken: {
    name: 'User token',
    pendingText: 'User token has been requested',
    questionText: 'Was the user token requested at the right time?',
    triggerdBy: ['requestUserToken'],
  },
  requestXsollaUserToken: {
    name: 'Xsolla token',
    pendingText: 'Xsolla token has been requested',
    questionText: 'Was the Xsolla token requested correctly?',
    triggerdBy: ['requestXsollaUserToken'],
  },
  useDataModule: {
    name: 'Data module',
    pendingText: 'Data module has been used',
    questionText: 'Was the data module correctly used?',
    triggerdBy: ['useDataModule'],
  },
};

// QA RESULTS ------------------------------------------------------------

export interface QAResult {
  unfulfilledMainRequirements: MainRequirement[];
  SDKImplemented: boolean;
  SDKFeatures: (SDKFeatures | SDKYesNoFeatures)[];
  failedSDKFeatures: (SDKFeatures | SDKYesNoFeatures)[];
  SDKInfo?: SDKInfo;
  adScenario: string;
  initialLoadSizeBytes?: number;
}

export interface PortalQAToolResult {
  requirements: {
    sdkRequirements: Record<SDKFeatures, RequirementState>;
    sdkYesNoRequirements: Record<SDKYesNoFeatures, YesNoRequirementState>;
    loading: RequirementState;
    displaysCorrectly: YesNoRequirementState;
  };
  submissionId: string;
  gameBuildId: string;
  SDKInfo?: SDKInfo;
  initialLoadSizeBytes: number | null;
}
