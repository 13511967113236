import { StyledBodyText } from '../../../../../../common/Styleguide/Common/Text';

export function renderSoftWarning(text: string | JSX.Element) {
  return (
    <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="warning">
      {text}
    </StyledBodyText>
  );
}
export function renderHardWarning(text: string | JSX.Element) {
  return (
    <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
      {text}
    </StyledBodyText>
  );
}
