import * as React from 'react';
import { StyledContainer } from '../../../../../../common/Styleguide/Common/Container';
import { StyledHeaderText } from '../../../../../../common/Styleguide/Common/Text';
import { StyledContainerHeader } from '../developerSubmission.styles';
import { Submission } from '../submission-graphql';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useTheme } from '@mui/material';
import { PieChartLegendDot, PieChartLegendItem } from './userFeedback.styles';

ChartJS.register(ArcElement, Tooltip, Legend);

const DATA_COLOURS = ['#12B16F', '#D61A3C', '#FC8314', '#13B4E7'];

interface UserFeedbackChartProps {
  submission: Submission;
}

const UserFeedbackChart: React.FunctionComponent<UserFeedbackChartProps> = () => {
  const { spacing } = useTheme();
  const data = {
    labels: ['Bugs', `Doesn't load`, 'Annoying ads', `Don't like it`],
    datasets: [
      {
        data: [310, 345, 390, 205],
        backgroundColor: DATA_COLOURS,
        borderColor: ['#000'],
        borderWidth: 1,
      },
    ],
  };

  return (
    <StyledContainer sx={{ pt: `calc(70px + ${spacing(3)})`, mt: 4 }}>
      <StyledContainerHeader>
        <StyledHeaderText variant="h2">Dislike details</StyledHeaderText>
      </StyledContainerHeader>
      <Pie data={data} options={{ plugins: { legend: { display: false } } }} />
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <PieChartLegendItem>
          <PieChartLegendDot style={{ background: DATA_COLOURS[0] }} />
          Bugs: 310
        </PieChartLegendItem>
        <PieChartLegendItem>
          <PieChartLegendDot style={{ background: DATA_COLOURS[1] }} />
          Doesn't load: 345
        </PieChartLegendItem>
        <PieChartLegendItem>
          <PieChartLegendDot style={{ background: DATA_COLOURS[2] }} />
          Annoying ads: 390
        </PieChartLegendItem>
        <PieChartLegendItem>
          <PieChartLegendDot style={{ background: DATA_COLOURS[3] }} />
          Don't like it: 205
        </PieChartLegendItem>
      </div>
    </StyledContainer>
  );
};

export default UserFeedbackChart;
