import { COLORS } from '../../../../../common/Styleguide/Common/colors';
import { DOCS_URL } from '../../../SideMenu/Menu';
import {
  DESKTOP_INITIAL_LOAD_LIMIT_MB,
  MAX_TOTAL_FILE_SIZE_MB,
  MAX_TOTAL_FILES_COUNT,
  MOBILE_INITIAL_LOAD_LIMIT_MB,
} from '../Submission/GameBaseForm/baseFormHelper';
import { renderHardWarning, renderSoftWarning } from '../Submission/GameBaseForm/utils';
import { BaseFormProblem, BaseFormSoftWarnings } from '../SubmitGame/submission-errors';

export function renderFileWarnings(hardWarnings: BaseFormProblem[], softWarnings: BaseFormSoftWarnings[]) {
  const hardWarningElements: JSX.Element[] = [];

  if (hardWarnings.includes('TOTAL_FILE_COUNT_EXCEEDED')) {
    hardWarningElements.push(
      renderHardWarning(`Your game has too many files, we don't accept submissions with more than ${MAX_TOTAL_FILES_COUNT} files.`),
    );
  }

  if (hardWarnings.includes('TOTAL_FILE_SIZE_EXCEEDED')) {
    hardWarningElements.push(
      renderHardWarning(`Your game is too large, we don’t accept submissions of more than ${MAX_TOTAL_FILE_SIZE_MB}MB.`),
    );
  }

  return (
    <>
      {hardWarningElements}

      {hardWarningElements.length === 0 &&
        softWarnings.includes('TOTAL_FILE_SIZE_TOO_BIG') &&
        renderSoftWarning(
          <>
            Your game is large. Note that games with an initial download size &gt;{DESKTOP_INITIAL_LOAD_LIMIT_MB}MB will be rejected, and
            games with an initial download size &gt;{MOBILE_INITIAL_LOAD_LIMIT_MB}MB will not be shown on the mobile homepage. This is
            measured up to the first gameplay start event sent through our SDK. Check our{' '}
            {
              <a
                href={`${DOCS_URL}/requirements/technical/`}
                style={{ color: COLORS.warning[100], textDecoration: 'underline' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                documentation
              </a>
            }{' '}
            for more info.
          </>,
        )}
    </>
  );
}
