import { BaseFormData, validateBaseForm } from '../Submission/GameBaseForm/baseFormHelper';
import { RevenueShareFormData } from '../Submission/RevenueShareForm/RevenueShareFormData';
import { DeveloperSubmissionCreateInput } from './submit-game-graphql';
import { unityLoadersWithCompression, loadersWithSaveDataToggles } from '../../../../../common/domain/game';
import { UploadedFile } from '../../../../../common/domain/upload';
import { convertGameCoversToInput, UploadedFileInput } from '../../../../../common/graphql/upload-input';
import { RevenueShareInputV3 } from '../Submission/Mutations/update-submission-revenue-mutation';
import { DeveloperSubmissionUpdateInput } from '../Submission/Mutations/update-submission-mutation';
import { DetailsFormData, validateDetailsForm } from '../Submission/GameDetailsForm/detailsFormHelper';
import cleanCopy from '../../../../../common/graphql/clean-copy';
import { Submission } from '../Submission/submission-graphql';

function uploadedFileToInput(file: UploadedFile): UploadedFileInput {
  const { path, size, uploadId } = file;
  return { path, size, uploadId };
}

export type UpdateStep = 'baseInfo' | 'details' | 'fullEdit';

export function revenueShareFormToInput(form: RevenueShareFormData): RevenueShareInputV3 {
  const { isEligible, makeExclusive, hasImplementedSDK, disallowEmbedding, suitableForChildren, hasCustomContract } = form;
  return {
    isEligible,
    makeExclusive,
    hasImplementedSDK,
    disallowEmbedding,
    suitableForChildren,
    hasCustomContract,
  };
}

export function submissionToUpdateGraphQLInput(
  initialSubmission: Submission,
  updateStep: UpdateStep,
  baseForm: BaseFormData,
  detailsForm?: DetailsFormData,
): DeveloperSubmissionUpdateInput {
  const problems = [detailsForm && validateDetailsForm(initialSubmission, detailsForm), validateBaseForm(baseForm)];

  if (!problems.every((form) => !form || form.length === 0)) {
    console.error('form was invalid', problems);
    throw new Error('INVALID_FORM');
  }

  return {
    id: initialSubmission?.id,
    ...submissionToCreateGraphQLInput(baseForm),
    description: detailsForm ? (detailsForm.description as string) : undefined,
    controls: detailsForm ? (detailsForm.controls as string) : undefined,
    playStoreLink: detailsForm ? (detailsForm.playUrl as string) : undefined,
    appStoreLink: detailsForm ? (detailsForm.appstoreUrl as string) : undefined,
    steamStoreLink: detailsForm ? (detailsForm.steamUrl as string) : undefined,
    categoryId: detailsForm ? (detailsForm.category as string) : undefined,
    playStoreDownloads: detailsForm ? parseInt(`${detailsForm.playStoreDownloads}`) || undefined : undefined,
    appStoreDownloads: detailsForm ? parseInt(`${detailsForm.appStoreDownloads}`) || undefined : undefined,
    steamDownloads: detailsForm ? parseInt(`${detailsForm.steamDownloads}`) || undefined : undefined,
    tagIds: detailsForm ? (detailsForm.tags as string[]) : undefined,
    // we dont reset gameFiles when changing engine type so the user is not frustrated when uploading files, selecting iframe
    // and then selecting unity again. We reset them here if needed
    gameCovers: detailsForm ? convertGameCoversToInput(detailsForm.gameCovers) : undefined,
    updateStep,
    videos: detailsForm?.videos ? cleanCopy(detailsForm?.videos, []) : null,
  };
}

export function submissionToCreateGraphQLInput(baseForm: BaseFormData): DeveloperSubmissionCreateInput {
  const problems = [validateBaseForm(baseForm)];

  if (!problems.every((form) => !form || form.length === 0)) {
    console.error('form was invalid', problems);
    throw new Error('INVALID_FORM');
  }

  const unityEncoding =
    baseForm.gameLoaderType && unityLoadersWithCompression.includes(baseForm.gameLoaderType) ? baseForm.unity56Encoding : undefined;

  const hasAPSSupport = baseForm.gameLoaderType && loadersWithSaveDataToggles.includes(baseForm.gameLoaderType);
  const unitySaveFileName = baseForm.unitySaveFileName;

  return {
    gameName: baseForm.name?.trim() as string,
    hasIAP: baseForm.hasIAP,
    isChromebookFriendly: baseForm.isChromebookFriendly,
    multiplayerOptions: baseForm.multiplayerOptions,
    gameLoaderType: baseForm.gameLoaderType!,
    fullscreen: baseForm.fullscreen,
    isIOSFriendly: !!baseForm.isIOSFriendly,
    isAndroidFriendly: !!baseForm.isAndroidFriendly,
    orientation: (baseForm.isIOSFriendly || baseForm.isAndroidFriendly) && baseForm.orientation ? baseForm.orientation : null,
    // we dont reset gameFiles when changing engine type so the user is not frustrated when uploading files, selecting iframe
    // and then selecting unity again. We reset them here if needed
    gameFiles: baseForm.gameLoaderType !== 'iframe' && baseForm.files ? baseForm.files.map(uploadedFileToInput) : [],
    iframeLink: baseForm.gameLoaderType === 'iframe' && baseForm.iframeLink ? baseForm.iframeLink.trim() : undefined,
    unity56Encoding: unityEncoding,
    // front end is now handling a single file name, while backend is storing them as an array
    unitySaveFileNames: hasAPSSupport && unitySaveFileName ? [unitySaveFileName] : undefined,
    apsDetail: hasAPSSupport
      ? {
          progressType: baseForm.progressSaveType || 'UNKNOWN',
        }
      : // the chances of this are very low, loaders without APS toggles are just Unity 5.4, we don't accept these for new games, and older ones won't be updated most likely
        { progressType: 'NO' },
    isGameJam: baseForm.isGameJam,
  };
}
