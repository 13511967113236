import { UploadFile } from '../../../../../common/DropZone/UploadDropZone';
import { SessionContextData } from '../../../../../common/Session/SessionContext';
import { getPresignedUrls, uploadWithPresignedUrl } from '../../../../../common/upload';
import { updateSubmission } from '../../UserDeveloper/Submission/Mutations/update-submission-mutation';
import { updateSubmissionAddQAResultAndNote } from '../../UserDeveloper/Submission/Mutations/update-submission-qa-result-mutation';
import { submissionSubmitForApproval } from '../../UserDeveloper/Submission/Mutations/update-submission-revenue-mutation';
import { submitGame } from '../../UserDeveloper/SubmitGame/submit-game-graphql';
import { GameBuild } from '../Submission/graphql/game-build-graphql';
import { ENABLE_LOCAL_UTILITIES } from './LocalUtilities';

interface CreateSubmissionReturnType {
  id: string;
  slug: string;
  gameBuilds: GameBuild[];
}

/** Create draft submission with engine and files, that didn't pass QA yet. */
export async function _localUtilityCreatePartialDraftSubmission(
  name: string,
  session: SessionContextData,
): Promise<CreateSubmissionReturnType> {
  if (!ENABLE_LOCAL_UTILITIES) {
    throw new Error('Local utilities are disabled');
  }

  const presignedResponses = await getPresignedUrls(
    [
      {
        path: 'index.html',
        blob: { name: 'index.html' } as File,
      } as UploadFile,
    ],
    session,
  );

  const fileToUpload: UploadFile = {
    state: 'WAITING',
    path: 'index.html',
    size: 0,
    imageUrl: null,
    blob: new File(
      [
        `<!DOCTYPE html>
          <html lang="en">
          <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Hello World</title>
          </head>
          <body>
              <h1>Hello, World!</h1>
          </body>
          </html>`,
      ],
      'index.html',
      { type: 'text/html' },
    ),
    presigned: {
      url: presignedResponses[0].presignedUrl,
      encoding: presignedResponses[0].encoding,
      type: presignedResponses[0].type,
    },
  };

  const uploadedFile = await uploadWithPresignedUrl(fileToUpload, session);

  const newSubmission = await submitGame({
    apsDetail: { progressType: 'SDKPS' },
    fullscreen: 'NOT_SUPPORTED',
    gameLoaderType: 'html5',
    gameName: name,
    hasIAP: false,
    isAndroidFriendly: false,
    isChromebookFriendly: false,
    isGameJam: false,
    isIOSFriendly: false,
    orientation: null,
    gameFiles: [
      {
        path: uploadedFile.fileName,
        size: uploadedFile.size,
        uploadId: uploadedFile.uploadId,
      },
    ],
  });

  if (!newSubmission.data?.developerSubmissionCreate) {
    throw new Error('Failed to create draft submission');
  }

  return newSubmission.data?.developerSubmissionCreate;
}

/** Create draft submission with engine, files, tag, categories, thumbnails, description etc. It needs the legal form to be completed now. */
export async function _localUtilityCreateFullDraftSubmission(
  name: string,
  session: SessionContextData,
): Promise<CreateSubmissionReturnType> {
  const submission = await _localUtilityCreatePartialDraftSubmission(name, session);

  await updateSubmissionAddQAResultAndNote({
    id: submission.id,
    gameBuildId: submission.gameBuilds[0].id,
    qaResult: {
      unfulfilledMainRequirements: ['isLoading', 'displaysCorrectly'],
      SDKImplemented: false,
      SDKFeatures: [],
      failedSDKFeatures: [],
      adScenario: '',
    },
    updateNoteText: '',
  });

  const file = submission.gameBuilds[0]?.files?.[0];

  await updateSubmission({
    id: submission.id,
    gameName: name,
    hasIAP: false,
    isChromebookFriendly: false,
    gameLoaderType: 'html5',
    fullscreen: 'NOT_SUPPORTED',
    isIOSFriendly: false,
    isAndroidFriendly: false,
    gameFiles: [
      {
        path: file!.fileName,
        size: file!.size,
        uploadId: file!.uploadFileId,
      },
    ],
    apsDetail: {
      progressType: 'SDKPS',
    },
    isGameJam: false,
    description: 'Description',
    controls: 'Controls',
    playStoreLink: undefined,
    appStoreLink: undefined,
    steamStoreLink: undefined,
    categoryId: '0376053f-8b1e-4ee9-8d3d-cb49a5f21d89',
    tagIds: ['338ace27-8fd2-438a-b9b8-de452ab0a3d8'],
    // these will be missing as you won't have the uploaded files in your DB locally
    gameCovers: [
      {
        size: '16x9',
        uploadedFile: {
          path: '_background2_1920x1080.png',
          uploadId: '0daf0e31-15bf-4f12-898f-092d484e60e8',
          size: 4517961,
        },
      },
      {
        size: '2x3',
        uploadedFile: {
          path: '_background2_800x1200.png',
          uploadId: '28831a06-af81-41a0-8b57-530ddc3c6dc6',
          size: 2140721,
        },
      },
      {
        size: '1x1',
        uploadedFile: {
          path: '_background2_800x800.png',
          uploadId: 'cd58b776-76b3-4384-8736-9fcc69b0c1fc',
          size: 3177343,
        },
      },
    ],
    updateStep: 'fullEdit',
    videos: null,
    orientation: null,
  });

  return submission;
}

/** Create a full submission that passed QA tool, has the legal form completed and is submitted. Requires approval and release now. */
export async function _localUtilityCreateSubmittedSubmission(
  name: string,
  session: SessionContextData,
): Promise<CreateSubmissionReturnType> {
  const submission = await _localUtilityCreateFullDraftSubmission(name, session);

  await submissionSubmitForApproval({
    id: submission.id,
    revenueShareInputV3: {
      isEligible: false,
      makeExclusive: false,
      hasImplementedSDK: false,
      disallowEmbedding: false,
      suitableForChildren: true,
      hasCustomContract: false,
    },
  });

  return submission;
}
