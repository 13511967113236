import config from '../../../../../../common/config';
import { DEFAULT_LOCALE } from '../../../../../../common/i18n';
import { QANextStep } from './qa-result-types';

interface PreviewRouteOptions {
  gameUrl?: string;
  minTimeForAds?: number;
  noTestAds?: boolean;
  secretSkipVideoAndFallback?: boolean;
  sdk_debug?: boolean;
  QANextStep?: QANextStep;
  draft?: boolean;
  gameBuildId?: string;
  enableUnityCache?: boolean;
  role?: 'developer' | 'admin';
  qaTool?: boolean;
  disableSubmitQA?: boolean;
}

const DEFAULT_OPTIONS: PreviewRouteOptions = { sdk_debug: true };

export function generateSubmissionPortalPreviewRoute(submissionId: string, options: PreviewRouteOptions = {}): string {
  let route = `${config.domains[DEFAULT_LOCALE].base}${config.domains[DEFAULT_LOCALE].pathname}/preview/${submissionId}`;
  const searchParams = generateCommonSearchParams(options);
  if (!searchParams.has('role')) {
    searchParams.set('role', 'developer');
  }
  const searchParamsString = searchParams.toString();
  if (searchParamsString) {
    route = `${route}?${searchParamsString}`;
  }
  return route;
}

function generateCommonSearchParams(options: PreviewRouteOptions) {
  const optionsWithDefault = { ...DEFAULT_OPTIONS, ...options };
  const searchParams = new URLSearchParams();
  const optionsKeys: (keyof PreviewRouteOptions)[] = Object.keys(optionsWithDefault) as (keyof PreviewRouteOptions)[];
  optionsKeys.forEach((optionKey: keyof PreviewRouteOptions) => {
    if (optionsWithDefault[optionKey] !== undefined) {
      if (typeof optionsWithDefault[optionKey] === 'string') {
        searchParams.set(optionKey.toString(), optionsWithDefault[optionKey] as string);
      } else {
        searchParams.set(optionKey.toString(), JSON.stringify(optionsWithDefault[optionKey]));
      }
    }
  });
  return searchParams;
}
