import { Button, ButtonProps, buttonClasses } from '@mui/base';
import { CircularProgress, SxProps } from '@mui/material';
import { alpha, styled, Theme } from '@mui/system';
import { CSSProperties } from '@mui/material/styles/createMixins';
import { COLORS } from './colors';
import React from 'react';

export const normalLineSpacing: React.CSSProperties = {
  paddingTop: '8px',
  paddingBottom: '8px',
};

type ButtonVariant = 'contained' | 'outlined' | 'link';

type ButtonColor =
  | 'purple'
  | 'grey'
  | 'darkGrey'
  | 'white'
  | 'red'
  | 'success'
  /** Yellow is a temporary color we use for the game jam banner */
  | 'yellow';
type ButtonShape = 'round' | 'square';

export interface StyledButtonProps {
  variant?: ButtonVariant;
  color?: ButtonColor;
  height?: number;
  fullWidth?: boolean;
  shape?: ButtonShape;
}
const commonButtonStyled = (theme: Theme, color: ButtonColor, variant: ButtonVariant, shape: ButtonShape): CSSProperties => {
  const getContainedBackgroundColor = () => {
    switch (color) {
      case 'grey':
        return COLORS.black[40];
      case 'darkGrey':
        return COLORS.black[70];
      case 'white':
        return COLORS.white[90];
      case 'red':
        return COLORS.alert[100];
      case 'success':
        return COLORS.success[100];
      case 'yellow':
        return '#E7DD21';
      case 'purple':
      default:
        return COLORS.brand[100];
    }
  };
  const getContainedHoverBackgroundColor = () => {
    switch (color) {
      case 'grey':
        return COLORS.black[50];
      case 'darkGrey':
        return COLORS.black[80];
      case 'white':
        return COLORS.white[60];
      case 'success':
        return COLORS.success[100];
      case 'red':
        return '#c40f50';
      case 'yellow':
        return '#d4ca11';
      case 'purple':
      default:
        return COLORS.brand[120];
    }
  };
  const getContainedColor = () => {
    switch (color) {
      case 'white':
        return COLORS.black[50];
      default:
        return COLORS.white[100];
    }
  };

  const getLinkColor = () => {
    switch (color) {
      case 'red':
        return COLORS.alert[100];
      case 'purple':
      default:
        return COLORS.brand[60];
    }
  };

  const getLinkHoverColor = () => {
    switch (color) {
      case 'red':
        return '#c40f50';
      case 'purple':
      default:
        return COLORS.brand[80];
    }
  };

  const getOutlinedHoverColor = () => {
    switch (color) {
      default:
        return COLORS.white[90];
    }
  };

  const getOutlinedBorderColor = () => {
    switch (color) {
      case 'darkGrey':
        return COLORS.black[10];
      default:
        return COLORS.white[30];
    }
  };

  const getOutlinedHoverBorderColor = () => {
    switch (color) {
      default:
        return COLORS.white[30];
    }
  };

  const getBorderRadius = () => {
    switch (shape) {
      case 'square':
        return 10;
      case 'round':
      default:
        return 30;
    }
  };

  return {
    borderRadius: getBorderRadius(),
    transition: 'all 250ms ease',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Nunito',
    fontWeight: 800,
    fontSize: 16,
    boxSizing: 'border-box',
    padding: theme.spacing(1, 2),
    '& svg': {
      height: 20,
      width: 20,
      marginRight: theme.spacing(),
    },
    background: 'transparent',
    ...(variant === 'contained' && {
      background: getContainedBackgroundColor(),
      color: getContainedColor(),
      '&:hover': {
        background: getContainedHoverBackgroundColor(),
      },
      '&:active': {
        background: alpha(getContainedHoverBackgroundColor(), 0.7),
      },

      [`&.${buttonClasses.disabled}`]: {
        background: alpha(getContainedBackgroundColor(), 0.5),
        cursor: 'default',
        '&:hover': {
          cursor: 'default',
          background: alpha(getContainedBackgroundColor(), 0.5),
        },
      },
    }),

    ...(variant === 'link' && {
      color: getLinkColor(),
      '&:hover': {
        color: getLinkHoverColor(),
      },
      '&:active': {
        color: alpha(getLinkColor(), 0.8),
      },
      [`&.${buttonClasses.disabled}`]: {
        color: alpha(getLinkColor(), 0.5),
        cursor: 'default',
        '&:hover': {
          color: alpha(getLinkColor(), 0.5),
        },
      },
    }),

    ...(variant === 'outlined' && {
      color: COLORS.white[70],
      boxShadow: `inset 0px 0px 0px 1px ${getOutlinedBorderColor()}`,
      '&:hover': {
        color: getOutlinedHoverColor(),
        boxShadow: `inset 0px 0px 0px 1px ${getOutlinedHoverBorderColor()}`,
      },
      [`&.${buttonClasses.disabled}`]: {
        color: alpha(COLORS.white[70], 0.5),
        cursor: 'default',
        '&:hover': {
          color: alpha(COLORS.white[70], 0.5),
        },
      },
    }),
  };
};

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'variant' && prop !== 'height' && prop !== 'color' && prop !== 'fullWidth' && prop !== 'shape' && prop !== 'sx',
})<StyledButtonProps>(({ variant = 'link', color = 'purple', shape = 'round', theme, height, fullWidth }) => ({
  ...commonButtonStyled(theme, color as any, variant, shape),
  height: height ? height : 40,
  ...(fullWidth && { width: '100%' }),
}));

interface StyledLoadingButtonProps {
  loading?: boolean;
  children: React.ReactNode;
}

type _StyledLoadingButtonProps = StyledLoadingButtonProps & StyledButtonProps & ButtonProps & SxProps;

export const StyledLoadingButton: React.FC<_StyledLoadingButtonProps> = ({ loading, children, ...rest }) => {
  if (loading) {
    return (
      <StyledButton {...rest} disabled={true} variant="contained" color="grey">
        <CircularProgress disableShrink={true} size={20} variant="indeterminate" />
      </StyledButton>
    );
  }
  return (
    <StyledButton {...rest} variant="contained">
      {children}
    </StyledButton>
  );
};
