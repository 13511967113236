import React from 'react';
import { Divider, Hidden, List } from '@mui/material';
import VideogameAsset from '@mui/icons-material/VideogameAsset';
import RecentActors from '@mui/icons-material/RecentActors';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InboxIcon from '@mui/icons-material/Inbox';
import LabelIcon from '@mui/icons-material/Label';
import CategoryIcon from '@mui/icons-material/Category';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import BuildIcon from '@mui/icons-material/Build';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PeopleIcon from '@mui/icons-material/People';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Construction, OpenInNewRounded, QueryStats, TrendingUp } from '@mui/icons-material';
import { StyledMenuHeader, StyledMenuItem, StyledMenuItemText } from './Menu.styles';
import AllGamesIcon from '../../../common/icons/AllGamesIcon';
import ExternalLinkIcon from '../../../common/icons/ExternalLinkIcon';
import AssetsIcons from '../../../common/icons/AssetsIcons';
import SupportIcon from '../../../common/icons/SupportIcon';
import ProfileIcon from '../../../common/icons/ProfileIcon';
import Star from '../../../common/icons/Star';
import { ROLE_V2, hasAdminAccess, hasCopywriterAccess, hasSubmissionAccess } from '../../../common/roles';
import { ENABLE_LOCAL_UTILITIES } from '../Content/Admin/LocalUtilities/LocalUtilities';

export interface Props {
  roles: ROLE_V2[];
}

export const DOCS_URL = 'https://docs.crazygames.com';
export const FAQ_URL = 'https://docs.crazygames.com/faq';

export type FullProps = RouteComponentProps<{}, {}> & Props;

class Menu extends React.Component<FullProps> {
  render() {
    return (
      <>
        {this.renderUserDeveloperMenu()}
        {this.renderAdminMenu()}
        {this.renderCopywriterMenu()}
      </>
    );
  }

  private renderUserDeveloperMenu(): JSX.Element {
    return (
      <List>
        <StyledMenuHeader>GAMES</StyledMenuHeader>
        {this.renderMenuItem('/games', 'My Games', <AllGamesIcon />)}
        <StyledMenuHeader>RESOURCES</StyledMenuHeader>
        <Hidden smDown>{this.renderMenuItem(DOCS_URL, 'SDK', <ExternalLinkIcon />, true)}</Hidden>
        <Hidden smDown>{this.renderMenuItem(`${DOCS_URL}/requirements/quality/`, 'Quality guidelines', <ExternalLinkIcon />, true)}</Hidden>
        <Hidden smDown>{this.renderMenuItem('/assets', 'Assets', <AssetsIcons />)}</Hidden>{' '}
        {this.renderMenuItem('/support', 'Support', <SupportIcon />)}
        <StyledMenuHeader>ACCOUNT</StyledMenuHeader>
        {this.renderMenuItem('/profile', 'Profile', <ProfileIcon />)}
        {this.renderMenuItem('/profile/billing', 'Billing', <AttachMoneyIcon />)}
        <Divider sx={{ mt: 1, mb: 0.5 }} />
      </List>
    );
  }

  private renderAdminMenu() {
    if (hasAdminAccess(this.props.roles)) {
      return (
        <List>
          {ENABLE_LOCAL_UTILITIES && this.renderMenuItem('/admin/local-utilities', 'Local utilities', <Construction />)}
          {this.renderMenuItem('/admin/developers', 'Developers', <RecentActors />)}
          {this.renderMenuItem('/admin/submissions/list', 'Submissions', <InboxIcon />)}
          {this.renderMenuItem('/admin/games', 'Games', <VideogameAsset />)}
          {this.renderMenuItem('/admin/stats', 'Stats', <QueryStats />)}
          {this.renderMenuItem('/admin/tags', 'Tags', <LabelIcon />)}
          {this.renderMenuItem('/admin/categories', 'Categories', <CategoryIcon />)}
          <Divider sx={{ my: 2 }} />
          {this.renderMenuItem('/admin/billing', 'Billing', <AttachMoneyIcon />)}
          {this.renderMenuItem('/admin/revenue-export', 'Revenue export', <FileDownloadIcon />)}
          {this.renderMenuItem('/admin/featured', 'Featured', <Star />)}
          {this.renderMenuItem('/admin/upload-game', 'Upload Game', <CloudUploadIcon />)}
          {this.renderMenuItem('/admin/notice', 'Notice', <NotificationsIcon />)}
          {this.renderMenuItem('/admin/roles', 'Manage Copywriters', <PeopleIcon />)}
          {this.renderMenuItem('/admin/adsConfig', 'Ads Config', <SettingsIcon />)}
          {this.renderMenuItem('/admin/adsTxt', 'Ads.txt', <MonetizationOnIcon />)}
          {this.renderMenuItem('/admin/appAdsTxt', 'app-ads.txt', <MonetizationOnIcon />)}
          {this.renderMenuItem('/admin/link-boosted-games', 'Link boost', <TrendingUp />)}
          {this.renderMenuItem('/admin/homepage-text', 'Homepage Text', <HomeIcon />)}
          {this.renderMenuItem('/admin/tools', 'Tools', <BuildIcon />)}
          {this.renderMenuItem('/admin/adGroups', 'Ad Groups', <SettingsIcon />)}
          {<Divider sx={{ mt: 1, mb: 0.5 }} />}
        </List>
      );
    }
    if (hasSubmissionAccess(this.props.roles)) {
      return (
        <List>
          {this.renderMenuItem('/admin/submissions/list', 'Submissions', <InboxIcon />)}
          {<Divider sx={{ mt: 1, mb: 0.5 }} />}
        </List>
      );
    }
  }

  private renderCopywriterMenu() {
    if (!hasCopywriterAccess(this.props.roles)) {
      return;
    }
    return (
      <List>
        {this.renderMenuItem('/copywriter/games', 'Games', <VideogameAsset />)}
        {this.renderMenuItem('/copywriter/tags', 'Tags', <LabelIcon />)}
        {this.renderMenuItem('/copywriter/categories', 'Categories', <CategoryIcon />)}
        {this.renderMenuItem('/copywriter/homepage-text', 'Homepage Text', <HomeIcon />)}
        {<Divider sx={{ mt: 1, mb: 0.5 }} />}
      </List>
    );
  }

  private renderMenuItem(to: string, text: string, icon: JSX.Element, externalLink: boolean = false) {
    if (externalLink) {
      return (
        <a target={'_blank'} href={to} rel="noreferrer">
          <StyledMenuItem>
            <OpenInNewRounded />
            <StyledMenuItemText>{text}</StyledMenuItemText>
          </StyledMenuItem>
        </a>
      );
    }

    return (
      <Link to={to}>
        <StyledMenuItem active={this.props.location.pathname === to}>
          {icon}
          <StyledMenuItemText>{text}</StyledMenuItemText>
        </StyledMenuItem>
      </Link>
    );
  }
}

export default withRouter(Menu);
