import { Moment } from 'moment';
import moment from 'moment';
import { StatsPeriodOptions, StatsPeriodValue } from './components/SelectTimeframe/SelectTimeframe';

export interface GraphInputTimespan {
  from: string;
  until: string;
}

export class Timespan {
  from: Moment;
  until: Moment;

  constructor(from: Moment, until: Moment) {
    this.from = from;
    this.until = until;
  }

  toGraphInputTimespan(): GraphInputTimespan {
    return {
      from: graphDateInputFormat(this.from),
      until: graphDateInputFormat(this.until),
    };
  }
}

export const GRAPHQL_DATE_FORMAT = 'YYYY-MM-DD';
export function graphDateInputFormat(date: Moment) {
  return date.format(GRAPHQL_DATE_FORMAT);
}

export const GRAPHQL_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export function graphDateTimeInputFormat(date: Moment) {
  return date.format(GRAPHQL_DATETIME_FORMAT);
}

export function graphParseDate(date: string) {
  return moment.utc(date, 'YYYY-MM-DD', true);
}

export function graphParseDateTime(date: string) {
  return moment.utc(date, 'YYYY-MM-DD HH:mm:ss', true);
}

export function timespansPerMonth(numberOfMonths: number): Timespan[] {
  const initial = moment.utc().startOf('month');

  return Array.from(Array(numberOfMonths)).map((_e, idx) => {
    const from = moment(initial).subtract(idx, 'month');
    return new Timespan(from, moment(from).endOf('month'));
  });
}

export function lastMonth(): Timespan {
  return lastMonthFrom(moment.utc());
}

export function lastMonthFrom(timestamp: number | Moment): Timespan {
  const previousMonthDate = moment(timestamp).subtract(1, 'month');
  const from = moment(previousMonthDate).startOf('month');
  const until = moment(previousMonthDate).endOf('month');
  return new Timespan(from, until);
}

export function lastWeekFrom(timestamp: number | Moment): Timespan {
  const previousWeekDate = moment(timestamp).subtract(1, 'week');
  const from = moment(previousWeekDate).startOf('day');
  const until = moment(timestamp).endOf('day');
  return new Timespan(from, until);
}

/**
 * Returns a string that can be used to denote a timespan spanning a specific month of a year
 */
export function slugifyMonth(timespan: Timespan): string {
  return timespan.from.format('MM-YYYY');
}

/**
 * From a slugified month, retrieve the original timespan
 */
export function deslugifyMonth(monthSlug: string): Timespan {
  const from = moment.utc(monthSlug, 'MM-YYYY').startOf('month');
  const until = moment.utc(from).endOf('month');
  return new Timespan(from, until);
}

/**
 * Returns a string that can be used to denote a timespan
 */
export function slugifyTimespan(timespan: Timespan): string {
  const { from, until } = timespan;
  return `${from.format('YYYYMMDD')}-${until.format('YYYYMMDD')}`;
}

export function statsPeriodToLabel(statsPeriod: StatsPeriodOptions): string {
  const { from, until } = statsPeriod;
  const fromMoment = moment(from);
  const toMoment = moment(until);
  return `${fromMoment.format('D MMM YYYY')} - ${toMoment.format('D MMM YYYY')}`;
}

/**
 * From a slugified timespan, retrieve the original timespan
 */
export function deslugifyTimespan(timespanSlug: string): Timespan {
  const parts = timespanSlug.split('-');
  if (parts.length !== 2) {
    throw new Error(`[datetime] could not match timespan: ${timespanSlug}`);
  }
  const [rawFrom, rawUntil] = parts;
  const from = moment.utc(rawFrom, 'YYYYMMDD').startOf('day');
  const until = moment.utc(rawUntil, 'YYYYMMDD').endOf('day');
  return new Timespan(from, until);
}

export function convertPeriodToOption(periodValue: StatsPeriodValue) {
  const yesterday = moment.utc().startOf('day').subtract(1, 'day').format(GRAPHQL_DATE_FORMAT);
  switch (periodValue) {
    case 'YESTERDAY':
      return {
        from: yesterday,
        until: yesterday,
      };
    case 'LAST_7_DAYS':
      return {
        from: moment.utc().startOf('day').subtract(7, 'day').format(GRAPHQL_DATE_FORMAT),
        until: yesterday,
      };
    case 'LAST_30_DAYS':
      return {
        from: moment.utc().startOf('day').subtract(30, 'day').format(GRAPHQL_DATE_FORMAT),
        until: yesterday,
      };
    case 'LAST_6_MONTHS':
      return {
        from: moment.utc().startOf('day').subtract(6, 'month').format(GRAPHQL_DATE_FORMAT),
        until: yesterday,
      };
    case 'ALL_TIME':
    default:
      return {
        from: moment.utc('2000-01-01', GRAPHQL_DATE_FORMAT).format(GRAPHQL_DATE_FORMAT),
        until: yesterday,
      };
  }
}
