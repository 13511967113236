import moment from 'moment';
import { GameBuild, GameBuildStatus } from '../components/App/Content/Admin/Submission/graphql/game-build-graphql';

// do not show admins drafts (devs haven't finished the submission flow yet) and accepted (if there are accepted builds, there must be an active build)
const GAME_BUILD_STATUSES_TO_HIDE_FROM_ADMIN = ['DRAFT', 'ACCEPTED'];

export function getLatestBuild(gameBuilds: GameBuild[], status?: GameBuildStatus) {
  return [...gameBuilds]
    .filter((build) => (status ? build.status === status : true))
    .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))[0];
}

export function getLatestBuildForAdmin(gameBuilds: GameBuild[]) {
  return (
    [...gameBuilds]
      .filter((build) => !GAME_BUILD_STATUSES_TO_HIDE_FROM_ADMIN.includes(build.status))
      .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))[0] || getLatestBuild(gameBuilds)
  );
}
