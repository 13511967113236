import lodash from '../lodash';

export interface GraphqlObjectData {
  __typename?: string;
}

// remove graphql fields from a given object
function cleanCopy<T>(graphqlObj: T & GraphqlObjectData, removeFields: string[]): T {
  const copy = lodash.clone(graphqlObj);
  removeFields.concat('__typename').forEach((field) => {
    if (Object.prototype.hasOwnProperty.call(copy, field)) {
      delete (copy as any)[field];
    }
  });
  return copy;
}

export default cleanCopy;
