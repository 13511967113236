import * as React from 'react';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import CzyCropper from '../components/CzyCropper/CzyCropper';
import CzyLoader from '../Loader';
import { uploadFile } from '../upload';
import { imageFileAsDataUrl } from '../file';
import { UploadedFile } from '../domain/upload';
import withSession, { WithSession } from '../Session/withSession';
import { toast } from 'react-toastify';
import { COLORS } from '../Styleguide/Common/colors';
import { StyledBodyText } from '../Styleguide/Common/Text';
import { StyledButton } from '../Styleguide/Common/Button';
import withTheme, { WithTheme } from '../../components/helpers/WithTheme';
import { COVER_MIN_DIMENSIONS } from '../thumbnail';
import { DOCS_URL } from '../../components/App/SideMenu/Menu';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import Edit from '../icons/Edit';
import DropZone, { DroppedFile } from '../DropZone/DropZone';

export interface Dimensions {
  width: number;
  height: number;
}

export interface ImageUploadProps extends WithSession {
  imageSource?: string;
  ratio: Dimensions;
  displayRatio?: Dimensions;
  allowRawUpload?: boolean;
  onChange: (uploadedFile: UploadedFile | null) => void;
  onUploadStarted?: () => void;
  onUploadComplete?: () => void;
  minWidth?: number;
  minHeight?: number;
  error?: boolean;
  isNonEditable?: boolean;
  preventInfoText?: boolean;
  label?: string;
  oneButtonLayout?: boolean;
}

type _ImageUploadProps = ImageUploadProps & WithTheme;

export interface ImageUploadState {
  file?: File;
  showEditor: boolean;
  saving: boolean;
  imageSource?: string;
}

class ImageUpload extends React.Component<_ImageUploadProps, ImageUploadState> {
  private uploadType: 'raw' | 'crop' | null;

  private inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: _ImageUploadProps) {
    super(props);
    this.inputRef = React.createRef<HTMLInputElement>();
    this.state = {
      showEditor: false,
      saving: false,
      imageSource: props.imageSource,
    };
    this.uploadType = null;
  }

  render() {
    const { ratio, displayRatio, theme, label, oneButtonLayout, isNonEditable, error } = this.props;
    const { saving, imageSource } = this.state;
    // Override paper's lineheight for images nested in it.
    // For small image ratio's there's a small white border due to the default lineheight.
    const paperStyle = {
      ...(imageSource ? { lineHeight: 0 } : {}),
      border: this.getBorder(),
      borderRadius: 8,
      boxSizing: 'border-box' as any,
    };
    return (
      <div>
        {label && (
          <Typography variant="subtitle1" sx={{ mb: 1.5 }}>
            <b>{label}</b>
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              opacity: 0.8,
              cursor: isNonEditable ? 'default' : 'pointer',
              '& button': {
                display: 'flex',
              },
            },
            '& button': {
              fontSize: 14,
              fontWeight: 800,
            },
          }}
        >
          <div onClick={isNonEditable ? () => {} : this.onUploadClicked}>
            <div style={{ ...paperStyle, position: 'relative' }}>
              <DropZone
                error={error}
                onFilesDropped={(files) => this.handleFilesDropped(files)}
                dropDisabled={isNonEditable}
                onError={this.onError}
              >
                <div style={{ display: 'none' }}>
                  <input ref={this.inputRef} type="file" onChange={this.onFileInputChange} />
                </div>
                {imageSource && <img src={imageSource} style={displayRatio || ratio} alt="upload" />}
                {!imageSource && this.renderNoImage()}
                {imageSource && oneButtonLayout && !isNonEditable && (
                  <StyledButton
                    variant="contained"
                    color="white"
                    onClick={this.onUploadClicked}
                    height={34}
                    sx={{ position: 'absolute', bottom: 16, right: 16, display: 'none' }}
                  >
                    <Edit /> Update
                  </StyledButton>
                )}
              </DropZone>
            </div>
          </div>
          {!oneButtonLayout && <div style={{ marginLeft: theme.spacing(3.5) }}>{this.renderActions(!!imageSource)}</div>}
        </Box>
        {this.renderEditor()}
        {saving && <CzyLoader />}
      </div>
    );
  }

  private getBorder() {
    const { error = false } = this.props;
    const { imageSource } = this.state;
    if (error) {
      return `1px dashed ${COLORS.alert[100]}`;
    }
    if (imageSource) {
      return '1px solid transparent';
    }
    return `2px dashed ${COLORS.black[30]}`;
  }

  private renderNoImage() {
    const { ratio, displayRatio, oneButtonLayout, isNonEditable } = this.props;
    return (
      <div
        style={{
          ...(displayRatio ? displayRatio : ratio),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {oneButtonLayout ? (
          <Grid container={true} alignItems="center" justifyContent="center" style={{ display: 'flex', flexDirection: 'column' }}>
            <StyledButton variant="contained" onClick={this.onUploadClicked} disabled={isNonEditable} height={34}>
              <PhotoCameraOutlinedIcon /> Upload
            </StyledButton>
            <StyledBodyText style={{ textAlign: 'center' }} color="white30">
              You can drag and drop the image here
            </StyledBodyText>
          </Grid>
        ) : (
          <PhotoCameraOutlinedIcon />
        )}
      </div>
    );
  }

  private renderEditor() {
    const { showEditor, file } = this.state;
    const { ratio, minWidth, minHeight, isNonEditable } = this.props;
    return (
      <Dialog open={showEditor} onClose={() => this.setState({ showEditor: false })}>
        <DialogTitle>
          Crop to ratio {ratio.width} x {ratio.height}
        </DialogTitle>
        <DialogContent>
          {file && (
            <CzyCropper
              isNonEditable={isNonEditable}
              minWidth={minWidth}
              minHeight={minHeight}
              file={file}
              ratio={ratio}
              onCrop={this.uploadImage}
            />
          )}
        </DialogContent>
      </Dialog>
    );
  }

  private renderActions(hasImage: boolean) {
    const { allowRawUpload, isNonEditable, preventInfoText } = this.props;
    const { width, height } = COVER_MIN_DIMENSIONS;
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {allowRawUpload && (
              <StyledButton variant="contained" onClick={this.onRawUploadClicked} sx={{ mt: 1.5, width: 170 }} disabled={isNonEditable}>
                Raw Upload
              </StyledButton>
            )}
            <StyledButton
              variant="contained"
              onClick={this.onUploadClicked}
              sx={{ mt: 1.5, width: 170, ml: allowRawUpload ? 2 : 0 }}
              disabled={isNonEditable}
            >
              Upload &amp; Crop
            </StyledButton>
            <StyledButton
              variant="contained"
              color="grey"
              sx={{ mt: 1.5, width: 170, ml: 2 }}
              disabled={!hasImage || isNonEditable}
              onClick={this.onClearClicked}
            >
              Clear
            </StyledButton>
          </div>
          {!preventInfoText && (
            <StyledBodyText variant="bodyLower" color="white30">
              We will use the cover image to show your game on our pages (homepage, category pages, …). Make it appealing and professional
              looking! A good cover image will make the users want to play your game. A cover should at least be {width} pixels wide and{' '}
              {height} pixels high. For more information, make sure to read our{' '}
              <a href={`${DOCS_URL}/requirements/game-covers/`} target="_blank" rel="noreferrer">
                guidelines for game covers
              </a>
              .
            </StyledBodyText>
          )}
        </div>
      </>
    );
  }

  private showIncorrectSizeToast = () => {
    const { minWidth, minHeight } = this.props;
    const errorMsg = `Incorrect image dimensions (min. width: ${minWidth}px / min. height: ${minHeight})`;
    toast.dismiss();
    toast.error(errorMsg, { autoClose: false });
  };

  private uploadImage = async (image: File) => {
    const { onChange, onUploadStarted, onUploadComplete, session } = this.props;
    this.setState({ showEditor: false, saving: true });
    toast.loading('Uploading image...');

    try {
      if (onUploadStarted) {
        onUploadStarted();
      }

      const uploads = await uploadFile(image, session, image.name);
      const imgSrc = await imageFileAsDataUrl(image);
      this.setState({ imageSource: imgSrc });
      const upload: UploadedFile = {
        uploadId: uploads[0].uploadId,
        path: image.name,
        size: image.size,
        temporaryUrl: '',
      };
      const hasRightDimensions = await this.checkMinimumImageSize(imgSrc);
      if (!hasRightDimensions) {
        this.showIncorrectSizeToast();
        this.setState({ imageSource: undefined });
        onChange(null);
        if (onUploadComplete) {
          onUploadComplete();
        }
        return;
      }
      toast.dismiss();
      toast.success('Image successfully uploaded!');
      if (onUploadComplete) {
        onUploadComplete();
      }
      onChange(upload);
    } catch (e) {
      toast.dismiss();
      toast.error('Upload failed. See console for details', { autoClose: false });
      console.error('[ImageUpload] Upload failed', e);
    } finally {
      this.setState({ saving: false });
    }
  };

  private checkMinimumImageSize = async (imgSrc: string) => {
    const { minWidth, minHeight } = this.props;
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imgSrc;
      img.onload = () => {
        if (!minWidth && !minHeight) {
          resolve(true);
        }
        if (minWidth !== undefined && img.width < minWidth) {
          resolve(false);
        }
        if (minHeight !== undefined && img.height < minHeight) {
          resolve(false);
        }
        resolve(true);
      };
    });
  };

  private handleFilesDropped = async (droppedFiles: DroppedFile[]) => {
    if (droppedFiles.length === 0) return;

    if (droppedFiles.length > 1) {
      toast.error('Please drop only one image file');
      return;
    }

    const file = droppedFiles[0].file;

    if (!file.type.startsWith('image/')) {
      toast.error('Please drop an image file');
      return;
    }

    this.uploadType = 'crop';

    try {
      const correctSize = await this.checkMinimumImageSize(URL.createObjectURL(file));
      if (!correctSize) {
        this.showIncorrectSizeToast();
        return;
      }

      this.setState({
        showEditor: true,
        file: file,
      });
    } catch (e) {
      toast.error('Drag and drop failed. See console for details', { autoClose: false });
      console.error('[ImageUpload] Drag and drop failed', e);
    }
  };

  private onError = (error: any) => {
    const errorMessage = error instanceof Error ? error.message : `Unknown file upload error ${error}`;
    toast.error(errorMessage);
  };

  private onUploadClicked = (ev: React.MouseEvent) => {
    if (ev) {
      ev.stopPropagation();
    }
    const fileInput = this.getFileInput();
    // custom select file button -> manual click to activate element
    // *may not work* in IE/Safari - to be verified
    this.uploadType = 'crop';
    fileInput.click();
  };

  private onRawUploadClicked = () => {
    const fileInput = this.getFileInput();
    // use local variable instead of state to avoid race condition between setState and onFileInputChange
    this.uploadType = 'raw';
    fileInput.click();
  };

  private onClearClicked = () => {
    const { onChange } = this.props;
    this.setState({ imageSource: undefined });
    onChange(null);
  };

  private onFileInputChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = evt.target;
    if (!files) {
      console.warn('Files is not set');
      return;
    }
    const file = files[0];
    // unset the value to allow to reselect the same file (does not trigger onChange otherwise)
    this.getFileInput().value = '';
    const { uploadType } = this;
    if (uploadType === 'raw') {
      this.uploadImage(file);
    } else if (uploadType === 'crop') {
      try {
        // try to check before launching the crop tool if the image has the minimum size
        const filePickerImageUrl = URL.createObjectURL(file);
        const correctSize = await this.checkMinimumImageSize(filePickerImageUrl);
        URL.revokeObjectURL(filePickerImageUrl);
        if (!correctSize) {
          this.showIncorrectSizeToast();
          return;
        }
      } catch (e) {
        console.error('Failed to determine in advance uploaded image size', e);
      }
      this.setState({
        showEditor: true,
        file: file,
      });
    } else {
      console.error('UploadType is not properly set');
      toast.error('Something went wrong, check console for more info');
    }
  };

  private getFileInput(): HTMLInputElement {
    const input = this.inputRef.current;
    if (!input) {
      console.error('UploadType is not properly set');
      throw new Error('Something went wrong, check console for more info');
    }
    return input;
  }
}

export default withSession<ImageUploadProps>(withTheme(ImageUpload));
