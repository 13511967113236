import { UploadedFile } from '../domain/upload';
import {
  GAME_COVERS_RESOLUTIONS,
  GameCoversWithUploadedFile,
  GameCoverWithUploadedFile,
  GameCoverWithUploadedFileInput,
} from './games/game';

export interface UploadedFileInput {
  path: string;
  uploadId: string;
  size: number;
}

export function uploadedFileToInput(file: UploadedFile): UploadedFileInput {
  return {
    path: file.path,
    uploadId: file.uploadId,
    size: file.size,
  };
}

export function convertArrayGameCoversToInput(gameCovers: GameCoverWithUploadedFile[]) {
  return gameCovers.map((gc) => ({
    ...gc,
    uploadedFile: gc.uploadedFile ? uploadedFileToInput(gc.uploadedFile) : null,
    __typename: undefined,
  }));
}

export function convertGameCoversToInput(gameCovers: GameCoversWithUploadedFile) {
  const output: GameCoverWithUploadedFileInput[] = [];
  for (const coverId in gameCovers) {
    const file = gameCovers[coverId as GAME_COVERS_RESOLUTIONS];
    if (file) {
      output.push({
        size: coverId as GAME_COVERS_RESOLUTIONS,
        uploadedFile: uploadedFileToInput(file),
      });
    }
  }
  return output;
}
