import { inputBaseClasses, outlinedInputClasses, styled } from '@mui/material';
import { COLORS } from './colors';
import { DatePicker } from '@mui/x-date-pickers';

export const StyledDatePicker = styled(DatePicker)(() => ({
  [`& .${inputBaseClasses.root}`]: {
    border: `2px solid ${COLORS.black[30]}`,
    borderRadius: '7px',
    outline: 'none',
    height: '40px',
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    display: 'none',
  },
}));
